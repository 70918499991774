import strFunc from "@/lib/lyg/string";
import DailyDataFunc from "@/pages/mobile/manage/edu/daily_report/common/card_list/func/daily_data_func";
import TextAreaComponent from "@/pcomponents/common/crud/write/textarea";
import AttendArea from "./area/attend";
import TodoArea from "./area/todo_area";
import DailyInputText from "./area/daily/input_text";
import HomeWorkStateInputText from "./area/daily/homework_state";

function CardBoxArea(props: any) {
  let myProps = {
    row_num: 0,
    lecture_info: null,
    info: {},
    set_info: (info: any) => { },
    xColumnArr: {},
    select_date: "",
    pre_date_str: "",
    list: (inData: any) => { },
    check_row_val_arr: [],
    set_check_row_val_arr: (inData: any) => { },
    Style: {},
    show_week_arr: [],
    lecture_time_str: "",
    select_stu_grade_arr: [],
    select_homework_state_arr: [],
    save_daily_report_by_stu_arr: (inData: any) => { },
    openAttendPopup: (inData: any) => { },
    deleteAttend: (inData: any) => { },
    open_write_td_data_popup: (inData: any) => { },
    openViewTodoStuPopup: (inData: any) => { },
    open_stu_layer_popup: (inData: any) => { },
    open_find_study_title_popup: (inData: any) => { },
    ...props
  };
  let Style = myProps.Style;
  let info = myProps.info;
  let daily_report_arr = [];
  if (info.daily_report_arr) {
    daily_report_arr = info.daily_report_arr;
  }
  let lecture_info = null;
  let lecture_seq = "";
  if (!strFunc.is_empty(myProps.lecture_info)) {
    lecture_info = myProps.lecture_info;
    lecture_seq = lecture_info["a_seq"];
  }
  let lecture_time_str = myProps.lecture_time_str;
  let card_row_pri_val = info["a_seq"]+","+lecture_seq+","+lecture_time_str;;

  let last_daily_info = {
    a_stu_seq: "",
    a_writer_seq: "",
    a_lecture_seq: "",
    a_textbook: "",
    a_subtextbook: "",
    a_homework: "",
  };
  if (info.last_daily_report_arr && info.last_daily_report_arr.length > 0 && lecture_info != null) {
    for (let i = 0; i < info.last_daily_report_arr.length; i++) {
      let row_daily_info = info.last_daily_report_arr[i];
      if (row_daily_info["a_stu_seq"] == info["a_seq"]
        //&& row_daily_info["a_writer_seq"] == lecture_info["a_main_teacher_seq"]
        && row_daily_info["a_lecture_seq"] == lecture_info["a_seq"]
        && strFunc.is_empty(last_daily_info["a_stu_seq"])) {
        last_daily_info = row_daily_info;
      }
    }
  }
  let daily_info = DailyDataFunc.get_daily_info_of_date({
    date_str: myProps.select_date,
    pre_date_str: myProps.pre_date_str,
    last_daily_info: last_daily_info,
    lecture_time_str: myProps.lecture_time_str,
    info: info,
    lecture_info: lecture_info,
  });

  let is_checked_row = false;
  if (strFunc.str_in_array(card_row_pri_val, myProps.check_row_val_arr) != -1) {
    is_checked_row = true;
  }

  let stu_grade_str = "";
  for (let i = 0; i < myProps.select_stu_grade_arr.length; i++) {
    if (info["a_stu_grade"] == myProps.select_stu_grade_arr[i]["value"]) {
      stu_grade_str = myProps.select_stu_grade_arr[i]["text"];
    }
  }

  const on_checkbox_change = (e: any) => {
    if (e.target.checked) {
      let change_check_row_val_arr = myProps.check_row_val_arr;
      change_check_row_val_arr.push(card_row_pri_val);
      myProps.set_check_row_val_arr(change_check_row_val_arr);
    } else {
      let change_check_row_val_arr = [];
      for (let i = 0; i < myProps.check_row_val_arr.length; i++) {
        if (myProps.check_row_val_arr[i] != card_row_pri_val) {
          change_check_row_val_arr.push(myProps.check_row_val_arr[i]);
        }
      }
      myProps.set_check_row_val_arr(change_check_row_val_arr);
    }
  };

  const on_change_daily_input = (inData: any) => {
    let opt_obj = {
      name: "",
      value: "",
      change_row_data: {},
      ...inData
    };
    let value = opt_obj.value;
    let name = opt_obj.name;
    let daily_row_num = -1;
    if (info.daily_report_arr && info.daily_report_arr.length > 0) {
      for (let i = 0; i < info.daily_report_arr.length; i++) {
        let row_daily_info = info.daily_report_arr[i];
        if(!strFunc.is_empty(daily_info["a_seq"])){
          if (row_daily_info["a_ymd"] == daily_info["a_ymd"]
            && row_daily_info["a_seq"] == daily_info["a_seq"]) {
            daily_row_num = i;
          }
        }else{
          if (row_daily_info["a_lecture_seq"] == daily_info["a_lecture_seq"]
            && row_daily_info["a_stu_seq"] == daily_info["a_stu_seq"]
            && row_daily_info["a_date"] == daily_info["a_date"]
            && row_daily_info["a_lecture_time"] == daily_info["a_lecture_time"]) {
            daily_row_num = i;
          }
        }
      }
    }

    daily_info[name] = value;
    for (let key in opt_obj.change_row_data) {
      daily_info[key] = opt_obj.change_row_data[key];
    }

    if (daily_row_num != -1) {
      info.daily_report_arr[daily_row_num] = daily_info;
    }
    if (daily_row_num == -1) {
      if (info.daily_report_arr == undefined) {
        info.daily_report_arr = [];
      }
      info.daily_report_arr.push(daily_info);
    }

    myProps.set_info(info);
  };

  let stu_name_style: any = { paddingLeft: "5px", paddingRight: "5px", borderRadius: "5px", color: "#000", fontWeight: 500 };
  if (strFunc.is_empty(daily_info["a_seq"])) {
    stu_name_style["color"] = "#fff";
    stu_name_style["background"] = "#888";
  }

  let manage_btn_text = "저장";
  let save_btn_class_name = "btn-s btn-gray";
  if (!strFunc.is_empty(daily_info["a_seq"])) {
    manage_btn_text = "수정";
    save_btn_class_name = "btn-s btn-yellowish2";
  }

  return (
    <div className={Style.card_box_wrap}>
      <div style={{ position: "relative" }}>
        <label>
          <input
            type="checkbox"
            onChange={on_checkbox_change}
            value={""}
            checked={is_checked_row}
          />
          <span className="ml-1">
            <span style={stu_name_style}>{info.a_user_name}</span>
            {info.a_stu_total_code &&
              <span className="ml-1" style={{ color: "#3fac96" }}>
                {info.a_stu_total_code}
              </span>
            }
            <span className="ml-1">{strFunc.cut_str(info["a_school_name"], 2, "")}</span>
            <span className="ml-1">{stu_grade_str}</span>
          </span>
        </label>
        <span style={{ position: "absolute", right: 0, top: 0 }}>
          <AttendArea
            info={info}
            openAttendPopup={myProps.openAttendPopup}
            deleteAttend={myProps.deleteAttend}
          ></AttendArea>
          <span className="ml-2">
            <button className={save_btn_class_name}
              onClick={() => {
                myProps.save_daily_report_by_stu_arr({
                  report_data_arr: [daily_info]
                });
              }}>{manage_btn_text}</button>
          </span>
        </span>
      </div>
      <div className="pl-4" style={{ display: "flex" }}>
        <div style={{ flexGrow: 1 }}>
          <div style={{ display: "inline-block", maxWidth: "49%" }}>
            <TodoArea
              info={info}
              select_date={myProps.select_date}
              pre_date_str={myProps.pre_date_str}
              openViewTodoStuPopup={myProps.openViewTodoStuPopup}
              sort="study"
            ></TodoArea>
          </div>
          <HomeWorkStateInputText
            daily_info={daily_info}
            name="a_study_state"
            onChange={on_change_daily_input}
            select_homework_state_arr={myProps.select_homework_state_arr}
          ></HomeWorkStateInputText>
        </div>
        <div style={{ flexGrow: 1 }}>
          <div style={{ display: "inline-block", maxWidth: "49%" }}>
            <TodoArea
              info={info}
              select_date={myProps.select_date}
              pre_date_str={myProps.pre_date_str}
              openViewTodoStuPopup={myProps.openViewTodoStuPopup}
              sort="pre_homework"
            ></TodoArea>
          </div>
          <HomeWorkStateInputText
            daily_info={daily_info}
            name="a_homework_state"
            onChange={on_change_daily_input}
            select_homework_state_arr={myProps.select_homework_state_arr}
          ></HomeWorkStateInputText>
        </div>
      </div>
      <div className={Style.daily_input_wrap}>
        <div className={Style.daily_input_title}>코어</div>
        <div className={Style.daily_input_div}>
          <DailyInputText
            daily_info={daily_info}
            name="a_textbook"
            onChange={on_change_daily_input}
            onClick={() => {
              myProps.open_find_study_title_popup({
                row_num: info["a_seq"],
                key_str: "a_textbook",
                par_info: daily_info
              });
            }}
          ></DailyInputText>
        </div>
        <div className={Style.daily_input_title}>솔루션</div>
        <div className={Style.daily_input_div}>
          <DailyInputText
            daily_info={daily_info}
            name="a_subtextbook"
            onChange={on_change_daily_input}
            onClick={() => {
              myProps.open_find_study_title_popup({
                row_num: info["a_seq"],
                key_str: "a_subtextbook",
                par_info: daily_info
              });
            }}
          ></DailyInputText>
        </div>
      </div>
      <div className={Style.daily_input_wrap}>
        <div className={Style.daily_input_title}>대</div>
        <div className={Style.daily_input_div}>
          <DailyInputText
            daily_info={daily_info}
            name="a_textbook_study"
            onChange={on_change_daily_input}
            info={info}
          ></DailyInputText>
        </div>
        <span className={Style.daily_input_title}>소</span>
        <div className={Style.daily_input_div}>
          <DailyInputText
            daily_info={daily_info}
            name="a_textbook_study_det"
            onChange={on_change_daily_input}
            info={info}
          ></DailyInputText>
        </div>
      </div>
      <div className={Style.daily_input_wrap}>
        <span className={Style.daily_input_title}>숙제</span>
        <div className={Style.daily_input_div}>
          <DailyInputText
            daily_info={daily_info}
            name="a_homework"
            onChange={on_change_daily_input}
          ></DailyInputText>
        </div>
        <div className={Style.daily_input_title} style={{ width: "50px" }}>학습량</div>
        <div className={Style.daily_input_div}>
          <DailyInputText
            daily_info={daily_info}
            name="a_study_amount_memo"
            onChange={on_change_daily_input}
          ></DailyInputText>
        </div>
      </div>
      <div className="mt-1">
        <div className="pl-2" style={{ display: "flex" ,gap:"5px"}}>
          <span>메모</span>
          <span style={{ display: "inline-block", flexGrow: 1 }}>
            <TextAreaComponent
              name="a_memo"
              value={daily_info["a_memo"]}
              onChange={(e: any) => {
                on_change_daily_input({
                  name: "a_memo",
                  value: e.target.value
                });
              }}
              placeholder="메모(300자 이내)"
            ></TextAreaComponent>
          </span>
        </div>
      </div>
    </div>
  );
};
export default CardBoxArea;