import strFunc from "@/lib/lyg/string";
import SelectBoxBar from "@/pcomponents/common/crud/write/select_box_bar";
import { useNavigate } from 'react-router-dom';
import option_ico_green_100 from "@/img/mobile/home/option_ico_green_100.png";

function TabArea(props: any) {
  let myProps = {
    page_tab: "daily",
    set_page_tab: (inData: any) => { },
    ...props
  };
  const navigate = useNavigate();
  let option_src = option_ico_green_100;

  return (
    <span className="text-center">
      <div style={{display:"inline-block"}}>
        <SelectBoxBar
          valueTextArr={[
            { value: "month_prescribe", text: "처방" },
            { value: "discover", text: "디커" },
          ]}
          value={myProps.page_tab}
          on_change={(inData: any) => {
            if (strFunc.is_empty(inData.value)) {
              inData.value = myProps.page_tab;
            }
            myProps.set_page_tab(inData.value);
            strFunc.set_storage("month_prescribe_tab", inData.value);
          }}
        ></SelectBoxBar>
      </div>
      <a onClick={() => { navigate("/mobile/setting") }} >
        <img src={option_src} className="ml-2" style={{ height: 27 }} />
      </a>
    </span>
  );
};
export default TabArea;