//import DailyReportDataFunc from "@/pcomponents/common/content/edu/daily_report/func/data_func";
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';

class DailyReportDataFunc
{
  static get_show_info_arr(inData:any){
    let opt_obj={
      infoArr:[],
      ...inData
    };
    let infoArr=opt_obj.infoArr;
    let select_stu_grade_arr = XcolumnFunc.getSortByTableKey({ 'table': 'user', 'key': 'a_stu_grade2' });
    let select_homework_state_arr = XcolumnFunc.getSortByTableKey({ 'table': 'daily_report', 'key': 'a_homework_state' });
    let this_obj=this;

    let show_info_arr: any = [];
    for (let i = 0; i < infoArr.length; i++) {
      let info = infoArr[i];
      let show_row: any = {};
      for (let key in info) {
        let val = "";
        if (info[key]) {
          val = info[key];
        }
        val=this_obj.get_show_val_of_daily_info({
          info:info,
          key:key,
          val:val,
          select_stu_grade_arr:select_stu_grade_arr,
          select_homework_state_arr:select_homework_state_arr
        });

        show_row[key] = val;
      }
      show_info_arr.push(show_row);
    }
    return show_info_arr;
  };
  static get_show_val_of_daily_info(inData:any){
    let opt_obj={
      info:{},
      key:"",
      val:"",
      select_stu_grade_arr:[],
      select_homework_state_arr:[],
      ...inData
    };
    let info=opt_obj.info;
    let key=opt_obj.key;
    let val=opt_obj.val;
    let select_stu_grade_arr=opt_obj.select_stu_grade_arr;
    let select_homework_state_arr=opt_obj.select_homework_state_arr;

    if (key == "a_stu_name") {
      if (info.stu_info) {
        let tmp_stu_grade = "";
        for (let i = 0; i < select_stu_grade_arr.length; i++) {
          if (select_stu_grade_arr[i]["value"] == info.stu_info["a_stu_grade"]) {
            tmp_stu_grade = select_stu_grade_arr[i]["text"];
          }
        }
        val = val + " " + tmp_stu_grade;
      }
    } else if (key == "a_homework_state" || key == "a_study_state") {
      for (let i = 0; i < select_homework_state_arr.length; i++) {
        if (select_homework_state_arr[i]["value"] == info[key]) {
          val = select_homework_state_arr[i]["text"];
          break;
        }
      }
    } else if (key == "a_textbook_study") {
      if (info["a_textbook_study_num"]) {
        val = info["a_textbook_study_num"] + ". " + val;
      }
    } else if (key == "a_textbook_study_det") {
      if (info["a_textbook_study_det_num"]) {
        val = info["a_textbook_study_det_num"] + ". " + val;
      }
    }

    return val;
  }
  static get_homework_per_by_state_str(inData:any){
    let opt_obj={
      state_str:"",
      ...inData
    };
    let state_str=opt_obj.state_str;
    let homework_per:any=0;
    if(state_str=="1"){
      homework_per=100;
    }else if(state_str=="2"){
      homework_per=0;
    }else if(state_str=="3"){
      homework_per=50;
    }else if(state_str=="4"){
      homework_per="";
    }
    return homework_per;
  };
  static get_show_plain_text_by_info(inData:any){
    let opt_obj={
      info:null,
      is_stu_name:true,
      is_time:true,
      is_amount_memo:false,
      is_memo:true,
      ...inData
    };
    let info=opt_obj.info;
    let show_info_arr=this.get_show_info_arr({infoArr:[info]});
    let show_info=show_info_arr[0];
    //없음 없애기
    for(let key in show_info){
      if(show_info[key]=="없음"){
        show_info[key]="";
      }
    }
    let home_work_percent=this.get_homework_per_by_state_str({state_str:info["a_homework_state"]});
    let show_plain_text:any="";
    let line_break="\n";
    
    if(opt_obj.is_stu_name){
      show_plain_text+=info["a_stu_name"]+" 학생 "+show_info["a_lecture_name"];
      if(opt_obj.is_time){
        show_plain_text+=" "+show_info["a_lecture_time"];
      }
      show_plain_text+=line_break;
    }
    
    show_plain_text+="<"+show_info["a_subject"]+">";
    if(home_work_percent!==""){
      show_plain_text+=" 숙제("+home_work_percent+"%) ";
    }
    show_plain_text+=" "+show_info["a_homework"];
    if(show_info["a_textbook"]){
      show_plain_text+=line_break;
      show_plain_text+=show_info["a_textbook"]+" / "+show_info["a_textbook_study"]+" / "+show_info["a_textbook_study_det"];
      if(show_info["a_subtextbook"]){
        show_plain_text+=" / "+show_info["a_subtextbook"];
      }
    }
    if(opt_obj.is_amount_memo){
      if(show_info["a_study_amount_memo"]){
        show_plain_text+=line_break;
        show_plain_text+=show_info["a_study_amount_memo"];
      }
    }
    if(opt_obj.is_memo){
      if(show_info["a_memo"]){
        show_plain_text+=line_break;
        show_plain_text+="(memo)"+show_info["a_memo"];
      }
    }
    
    return show_plain_text;
  }
  static get_show_plain_text_by_info_arr(inData:any){
    let opt_obj={
      infoArr:[],
      ...inData
    };
    let infoArr=opt_obj.infoArr;
    let show_plain_text_arr=[];
    let this_obj=this;
    for(let i=0;i<infoArr.length;i++){
      let info=infoArr[i];
      let show_plain_text=this_obj.get_show_plain_text_by_info({info:info});
      show_plain_text_arr.push(show_plain_text);
    }
    return show_plain_text_arr;
  }
}
export default DailyReportDataFunc;