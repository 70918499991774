import DailyReportTextListArea from "@/pages/mobile/manage/edu/daily_report/common/daily_report_text_list";

function DailyReportTextListPopup(props: any) {
  let myProps = {
    select_mcomp_data: {
      mcomp_seq: "",
      api_key: "",
      api_user_seq: "",
    },
    select_date: "",
    stu_info_arr: [],
    closePopup: () => { },
    ...props
  };

  return (
    <div>
      <DailyReportTextListArea
        select_mcomp_data={myProps.select_mcomp_data}
        select_date={myProps.select_date}
        stu_info_arr={myProps.stu_info_arr}
        closePopup={myProps.closePopup}
      ></DailyReportTextListArea>
    </div>
  );
};
export default DailyReportTextListPopup;