import green_char from "@/img/mobile/char/green_char.png";
import red_char from "@/img/mobile/char/red_char.png";

const ChatRowTag = (props: any) => {
  let myProps = {
    chat_row: {},
    is_display_title: true,
    ...props
  };
  let chat_row = myProps.chat_row;
  let is_display_title = myProps.is_display_title;
  let is_iam = false;
  if (chat_row["role"] == "user") {
    is_iam = true;
  }

  let row_class_name = "chat_view_msg_row";
  let writer="그로봇";
  if (is_iam) {
    row_class_name += " my_msg_row";
    writer="나";
  }

  let row_profile_img_src = red_char;
  if (chat_row["role"] == "user") {
    row_profile_img_src = green_char;
  }

  return (
    <div className={row_class_name} >
      {(is_iam == false && is_display_title) &&
        <div className="chat_view_msg_row_title_box">
          <span className="chat_view_msg_row_title_profile_img_box">
            <img src={row_profile_img_src} />
          </span>
          <span className="chat_view_msg_row_title" >{writer}</span>
        </div>
      }
      <div className="chat_view_msg_row_con_box">
        <div className="chat_view_msg_row_con_msg">
          {
            chat_row.content.split('\n').map((line: string, idx: number) => {
              return (<p key={idx}>{line}</p>)
            })
          }
        </div>
      </div>
    </div>
  );
};
export default ChatRowTag;