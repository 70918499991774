import TimeTableStuWeekPage from "@/pages/mobile/edu/time_table_week";
import AttendCalendarPage from "@/pages/mobile/edu/attend/calendar";
import PlayGroundMainPage from "@/pages/mobile/playground/main";
import MathPlusBasicPage from "@/pages/mobile/playground/math/plus/basic";
import MathMinusBasicPage from "@/pages/mobile/playground/math/minus/basic";
import MathMultyBasicPage from "@/pages/mobile/playground/math/multiply/basic";
import MathDivideBasicPage from "@/pages/mobile/playground/math/divide/basic";
import StudyMapListPage from "@/pages/mobile/plan/study_map/list";
import AppSoundManagePage from "@/pages/mobile/plan/app_sound/manage";

const stuRouterArr:object[] = [
  {
    path: "/mobile/edu/time_table_week",
    element: <TimeTableStuWeekPage></TimeTableStuWeekPage>,
  },
  {
    path: "/mobile/edu/attend_calendar",
    element: <AttendCalendarPage></AttendCalendarPage>,
  },
  {
    path: "/mobile/playground",
    element: <PlayGroundMainPage></PlayGroundMainPage>,
  },
  {
    path: "/playground/math/plus/basic",
    element: <MathPlusBasicPage></MathPlusBasicPage>,
  },
  {
    path: "/playground/math/minus/basic",
    element: <MathMinusBasicPage></MathMinusBasicPage>,
  },
  {
    path: "/playground/math/multy/basic",
    element: <MathMultyBasicPage></MathMultyBasicPage>,
  },
  {
    path: "/playground/math/divide/basic",
    element: <MathDivideBasicPage></MathDivideBasicPage>,
  },
  {
    path: "/mobile/plan/study_map/list",
    element: <StudyMapListPage></StudyMapListPage>,
  },
  {
    path: "/mobile/plan/app_sound/manage",
    element: <AppSoundManagePage></AppSoundManagePage>,
  },
];

export default stuRouterArr;