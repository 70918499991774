import { useState, useRef, useEffect, forwardRef, useImperativeHandle } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import strFunc from '@/lib/lyg/string';
import DateFunc from '@/lib/lyg/date_func';
import DiscoverMapDataFunc from "@/pcomponents/common/content/edu/discover_map/func/data_func";
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';
import { useSelector, useDispatch } from 'react-redux';
import UserFunc from "@/pcomponents/common/content/user/data/data_func";
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import FindMultyStudentPopup from "@/pages/mobile/manage/basic/student/popup/find_multy";
import WriteArea from "./area/write_area";
import ListHistoryArea from "./area/list_history";

const DisCoverMapWriteArea = forwardRef((props: any, ref) => {
  let myProps = {
    select_mcomp_data: {
      mcomp_seq: "",
      api_key: "",
      api_user_seq: "",
    },
    stu_seq: "",
    info: {},
    is_update: false,
    list: (inOptObj: any) => { },
    is_view_mode: true,
    is_show_history: true,
    is_show_find_stu: false,
    is_close_btn: false,
    close_btn_text: "닫기",
    close_func: () => { },
    ...props
  };
  let user = useSelector((state: any) => state.user);
  let iam_mcomp_data = UserFunc.get_iam_mcomp_data({
    user: user,
    select_mcomp_seq: myProps.select_mcomp_data.select_mcomp_seq,
    default_mcomp_api_key: myProps.select_mcomp_data.api_key,
    default_mcomp_user_seq: myProps.select_mcomp_data.api_user_seq,
  });
  let iam_mcomp_user_seq = iam_mcomp_data.iam_mcomp_user_seq;
  let iam_mcomp_user_name = iam_mcomp_data.iam_mcomp_user_name;
  let iam_mcomp_seq = iam_mcomp_data.iam_mcomp_seq;
  let iam_mcomp_user_grade = iam_mcomp_data.iam_mcomp_user_grade;
  let comp_api_key = iam_mcomp_data.comp_api_key;

  let now_ymd = DateFunc.get_date_format(new Date(), "Ymd");
  let now_y_m_d = DateFunc.get_date_format(new Date(), "Y-m-d");
  let default_info_data = {
    ...DiscoverMapDataFunc.default_info_data,
    a_ymd: now_ymd,
    a_seq: "",
    a_date: now_y_m_d,
    a_writer_seq: iam_mcomp_user_seq,
    a_writer: iam_mcomp_user_name,
    a_is_req_prescribe: "",
  };
  const [info, set_info] = useState({
    ...default_info_data,
    ...myProps.info
  });
  const [info_arr, set_info_arr] = useState([]);
  const [stu_info, set_stu_info] = useState<any>({});
  const [is_update, set_is_update] = useState(myProps.is_update);
  const [is_view_mode, set_is_view_mode] = useState(myProps.is_view_mode);
  const [select_mcomp_data, set_select_mcomp_data] = useState(myProps.select_mcomp_data);
  const default_list_opt = {
    s_date_type: "a_date",
    s_start_date: "",
    s_end_date: "",
    s_stu_seq: "",
    order_id: "a_date DESC, a_create_date DESC",
    s_addon_daily_report_en_math: "1",
    "is_need_count": "",
    "is_need_info_arr": "1",
    "is_no_limit": "1",

    select_pri_seq: "",
  };
  const [list_opt, set_list_opt] = useState({
    ...default_list_opt
  });
  let stu_grade_option_arr = XcolumnFunc.getSortByTableKey({ 'table': 'user', 'key': 'a_stu_grade' });

  const [popupData, setPopupData] = useState({
    isOpen: false,
    sort: "find",//view
    isUpdate: false,
    rowData: {},
    has_header: true,
    title: "팝업",
    width: "800px",
    height: "80%",
  });

  useEffect(() => {
    if (!strFunc.is_empty(myProps.stu_seq)) {
      initData({
        stu_seq: myProps.stu_seq,
        info: myProps.info,
        is_update: myProps.is_update,
        is_view_mode: myProps.is_view_mode
      });
    }
  }, [myProps.stu_seq]);

  const initData = (inData: any) => {
    let opt_obj = {
      select_mcomp_data: select_mcomp_data,
      stu_seq: stu_info ? stu_info["a_seq"] : "",
      info: info,
      is_update: false,
      is_view_mode: is_view_mode,
      list_opt: { ...list_opt },
      ...inData
    };
    let change_row_info: any = {
      ...DiscoverMapDataFunc.default_info_data,
      ...opt_obj.info,
    };
    if (opt_obj.stu_seq) {
      get_stu_info_by_ajax({
        stu_seq: opt_obj.stu_seq,
        is_update: opt_obj.is_update,
      });
    } else {
      set_stu_info({});
    }
    opt_obj.list_opt = {
      ...default_list_opt,
      ...list_opt,
      ...opt_obj.list_opt
    };
    set_info(change_row_info);
    set_is_update(opt_obj.is_update);
    set_is_view_mode(opt_obj.is_view_mode);
    set_select_mcomp_data(opt_obj.select_mcomp_data);
    let select_pri_seq = "";
    if (opt_obj.stu_seq) {
      opt_obj.list_opt["s_stu_seq"] = opt_obj.stu_seq;
      if (opt_obj.info["a_seq"]) {
        select_pri_seq = opt_obj.info["a_ymd"] + "," + opt_obj.info["a_seq"];
      }
      opt_obj.list_opt.select_mcomp_data = opt_obj.select_mcomp_data;
      get_discover_arr_by_ajax({
        list_opt: opt_obj.list_opt,
        select_mcomp_data: opt_obj.select_mcomp_data,
        is_update: opt_obj.is_update,
        select_pri_seq: select_pri_seq,
      });
    }
  };

  useImperativeHandle(ref, () => ({
    // 부모 컴포넌트에서 사용할 함수를 선언
    initData
  }));

  const get_discover_arr_by_ajax = (inData: any) => {
    let opt_obj = {
      list_opt: list_opt,
      select_mcomp_data: select_mcomp_data,
      is_update: is_update,
      select_pri_seq: "",
      ...inData,
    };
    let change_list_opt = {
      ...list_opt,
      select_mcomp_data: select_mcomp_data,
      ...inData.list_opt
    };
    set_list_opt(change_list_opt);
    if (strFunc.is_empty(change_list_opt["s_stu_seq"])) {
      return false;
    }
    if (!strFunc.is_empty(opt_obj.select_mcomp_data.api_key)
      && !strFunc.is_empty(opt_obj.select_mcomp_data.api_user_seq)) {
      change_list_opt["api_key"] = opt_obj.select_mcomp_data.api_key;
      change_list_opt["api_user_seq"] = opt_obj.select_mcomp_data.api_user_seq;
    }

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/discover_map/list', change_list_opt, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          let tmp_info_arr = response.data["data"]["info_arr"];

          if (tmp_info_arr.length > 0) {
            if (opt_obj.is_update && strFunc.is_empty(opt_obj["select_pri_seq"])) {
              set_info(tmp_info_arr[0]);
            } else {
              for (let i = 0; i < tmp_info_arr.length; i++) {
                let tmp_info = tmp_info_arr[i];
                let tmp_row_pri_val = tmp_info["a_ymd"] + "," + tmp_info["a_seq"];
                if (tmp_row_pri_val == opt_obj["select_pri_seq"]) {
                  set_info(tmp_info);
                  break;
                }
              }
            }
          } else {
            set_is_update(false);
            get_stu_info_by_ajax({
              stu_seq: opt_obj.list_opt["s_stu_seq"],
              is_update: false,
            });
          }
          set_info_arr(response.data["data"]["info_arr"]);
        }
      });
  };

  const get_stu_info_by_ajax = (inData: any) => {
    let opt_obj = {
      stu_seq: "",
      is_update: is_update,
      ...inData
    };
    if (strFunc.is_empty(opt_obj.stu_seq)) {
      return false;
    }
    let formJsonData: any = {
      "s_pri_arr": [opt_obj.stu_seq],
      "s_addon_add_memo": "1",
      "s_addon_daily_report_last": "1",
      // s_addon_discover_map: "1",
      // s_addon_discover_map_start_date: "",
      // s_addon_discover_map_end_date: "",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };
    if (!strFunc.is_empty(select_mcomp_data.api_key)
      && !strFunc.is_empty(select_mcomp_data.api_user_seq)) {
      formJsonData["api_key"] = select_mcomp_data.api_key;
      formJsonData["api_user_seq"] = select_mcomp_data.api_user_seq;
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list', formJsonData, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          if (response.data["data"]["info_arr"].length > 0) {
            let user_info = response.data["data"]["info_arr"][0];
            set_stu_info(user_info);
            if (opt_obj.is_update == false) {
              let change_row_info = DiscoverMapDataFunc.get_info_of_stu_by_stu_info({
                info: default_info_data,
                stu_info: user_info,
                stu_grade_option_arr: stu_grade_option_arr
              });
              set_info(change_row_info);
            }
          }
        }
      });
  };

  const write_by_ajax = (inData: any) => {
    let opt_obj = {
      info: info,
      ...inData
    };
    let row_data = opt_obj.info;

    if (strFunc.is_empty(row_data["a_date"])) {
      alert("날짜를 입력해주세요.");
      return false;
    }
    if (strFunc.is_empty(row_data["a_stu_seq"])) {
      alert("학생선택이 필요합니다.");
      return false;
    }
    if (confirm("저장하시겠습니까?") == false) {
      return false;
    }
    let w_info: any = {
      ...row_data
    };
    if (!strFunc.is_empty(row_data["a_seq"])) {
      if (row_data["a_writer_seq"] != user.user_seq) {
        w_info["a_reviewer_seq"] = user.user_seq;
        w_info["a_reviewer"] = user.user_name;
      }

    }
    if (strFunc.is_empty(row_data["a_seq"])) {
      w_info["a_ymd"]=DateFunc.get_date_format(new Date(row_data["a_date"]), "Ymd");
    }
    let from_json_data: any = {
      data_arr: [w_info],
    };
    if (strFunc.is_empty(row_data["a_seq"])) {
      from_json_data["is_default_val"] = "1";
    }
    if (!strFunc.is_empty(select_mcomp_data.api_key)
      && !strFunc.is_empty(select_mcomp_data.api_user_seq)) {
      from_json_data["api_key"] = select_mcomp_data.api_key;
      from_json_data["api_user_seq"] = select_mcomp_data.api_user_seq;
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/discover_map/write', from_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert("저장 되었습니다.");
          myProps.list({});

          initData({
            stu_seq: stu_info["a_seq"],
            info: response.data["data"][0],
            info_arr: response.data["data"],
            is_update: true,
          });
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const go_success_by_ajax = (inData: any) => {
    let opt_obj = {
      info: {},
      is_success: true,
      ...inData
    };
    let confirm_msg = "처방완료 하시겠습니까?";
    if (opt_obj.is_success == false) {
      confirm_msg = "처방완료를 취소 하시겠습니까?";
    }
    if (confirm(confirm_msg) == false) {
      return false;
    }

    let w_info: any = {
      ...info
    };
    if (opt_obj.is_success) {
      w_info["a_is_success"] = "1";
      w_info["a_success_date"] = DateFunc.get_date_format(new Date(), "Y-m-d h:i:s");
      w_info["a_reviewer_seq"] = user.user_seq;
      w_info["a_reviewer"] = user.user_name;
    } else {
      w_info["a_is_success"] = "";
    }

    let from_json_data: any = {
      data_arr: [w_info],
      is_update: "1"
    };
    if (!strFunc.is_empty(select_mcomp_data.api_key)
      && !strFunc.is_empty(select_mcomp_data.api_user_seq)) {
      from_json_data["api_key"] = select_mcomp_data.api_key;
      from_json_data["api_user_seq"] = select_mcomp_data.api_user_seq;
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/discover_map/write', from_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert("변경 되었습니다.");
          set_info(w_info);
          myProps.list({});
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const delete_by_ajax = (inData: any) => {
    let opt_obj = {
      del_info: {},
      ...inData
    };
    let del_info = opt_obj.del_info;
    if (strFunc.is_empty(del_info["a_seq"])) {
      alert("데이터 키가 없습니다.");
      return false;
    }

    if (confirm("삭제하시겠습니까?") == false) {
      return false;
    }

    let from_json_data: any = {
      data_arr: [del_info],
    };
    if (!strFunc.is_empty(select_mcomp_data.api_key)
      && !strFunc.is_empty(select_mcomp_data.api_user_seq)) {
      from_json_data["api_key"] = select_mcomp_data.api_key;
      from_json_data["api_user_seq"] = select_mcomp_data.api_user_seq;
    }
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/discover_map/delete', from_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          alert("삭제 되었습니다.");
          myProps.list({});
          let del_pri_val = del_info["a_ymd"] + "," + del_info["a_seq"];
          let new_info_arr: any = [];
          for (let i = 0; i < info_arr.length; i++) {
            let tmp_info = info_arr[i];
            let tmp_row_pri_val = tmp_info["a_ymd"] + "," + tmp_info["a_seq"];
            if (tmp_row_pri_val != del_pri_val) {
              new_info_arr.push(tmp_info);
            }
          }
          if (new_info_arr.length > 0) {
            initData({
              stu_seq: stu_info["a_seq"],
              info: new_info_arr[0],
              info_arr: new_info_arr,
              is_update: true,
              is_view_mode: true,
            });
          } else {
            initData({
              stu_seq: stu_info["a_seq"],
              info: {},
              info_arr: new_info_arr,
              is_update: false,
              is_view_mode: true,
            });
          }
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const open_find_stu_popup = () => {
    let pop_title = "학생찾기";
    setPopupData({
      ...popupData,
      "isOpen": true,
      "title": pop_title,
      "sort": "find",
      width: "800px",
      height: "80%",
    });
  };

  const callBackDataStuPopup = (inData: any) => {
    let optObj = {
      info_arr: [],
      row_num: 0,
      key: "",
      ...inData,
    };
    if (optObj["info_arr"].length > 0) {
      let select_stu_info = optObj["info_arr"][0];
      initData({
        stu_seq: select_stu_info["a_seq"],
        info: {},
        is_update: false,
        is_view_mode: false,
      });
    }
  };

  return (
    <div className="noto_sans">
      <div className="text-center">
        {(is_view_mode == false && !strFunc.is_empty(stu_info.a_seq)) &&
          <span>
            <button className="btn btn-yellowish2 ml-1" onClick={() => { write_by_ajax({}); }} >
              {is_update ? "수정" : "등록"}
            </button>
            {info.a_is_req_prescribe != "1" &&
              <button className="btn btn-yellowish2 ml-1"
                onClick={() => {
                  let change_info = {
                    ...info,
                    a_is_req_prescribe: "1",
                    a_req_prescribe_date: DateFunc.get_date_format(new Date(), "Y-m-d h:i:s"),
                  };
                  write_by_ajax({
                    info: change_info
                  });
                }} >
                처방요청
              </button>
            }
          </span>
        }
        {(is_update && !strFunc.is_empty(stu_info.a_seq) && info.a_is_req_prescribe == "1") &&
          <span>
            {info["a_is_success"] != "1" ?
              <button className="btn btn-blue ml-1"
                onClick={() => { go_success_by_ajax({ is_success: true }); }}>처방완료</button>
              :
              <button className="btn btn-gray ml-1"
                onClick={() => { go_success_by_ajax({ is_success: false }); }}>완료취소</button>
            }
          </span>
        }
        {strFunc.is_empty(stu_info.a_seq) ? "학생 선택이 필요합니다." :
          <span>
            <button className="btn btn-dark ml-1" onClick={() => { set_is_view_mode(!is_view_mode); }}>
              {is_view_mode ? "수정모드" : "보기모드"}
            </button>
          </span>
        }
        {(myProps.is_show_find_stu && strFunc.is_empty(stu_info.a_seq)) &&
          <button className="btn btn-sky ml-1" onClick={open_find_stu_popup}>찾기</button>
        }
        {is_update &&
          <button className="btn btn-sky ml-1" onClick={() => { 
            initData({
              info:{...default_info_data},
              is_view_mode: false,
              is_update: false,
            });
          }}>신규등록</button>
        }
        {myProps.is_close_btn &&
          <button className="btn btn-dark ml-1"
            onClick={() => { myProps.close_func(); }}
          >{myProps.close_btn_text}</button>
        }
      </div>
      <div>
        <WriteArea
          info={info}
          set_info={set_info}
          stu_info={stu_info}
          is_view_mode={is_view_mode}
        ></WriteArea>
        작성시간: {info["a_create_date"]}
        {info.a_is_success == "1" &&
          <p>처방완료:{info["a_success_date"]}</p>
        }
      </div>
      {
        myProps.is_show_history &&
        <div className="mt-1" style={{ background: "#fff" }}>
          히스토리
          <ListHistoryArea
            info={info}
            set_info={(inData: any) => {
              initData({
                stu_seq: stu_info["a_seq"],
                info: inData,
                info_arr: info_arr,
                is_update: true,
                is_view_mode: true,
              });
            }}
            info_arr={info_arr}
            delete_by_ajax={delete_by_ajax}
          ></ListHistoryArea>
        </div>
      }
      {popupData.isOpen &&
        <LayerPopup closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }} title={popupData.title}
          width={popupData.width} height={popupData.height} has_header={popupData.has_header} y={"0"} >
          {popupData.sort === "find" &&
            <FindMultyStudentPopup
              select_mcomp_seq={iam_mcomp_seq}
              select_mcomp_api_key={comp_api_key}
              select_mcomp_user_seq={iam_mcomp_user_seq}
              callBackData={callBackDataStuPopup}
              listOpt={{
                s_addon_discover_map: "1",
              }}
              is_show_discover_map={true}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}></FindMultyStudentPopup>
          }
        </LayerPopup>
      }
    </div>
  );
});
export default DisCoverMapWriteArea;