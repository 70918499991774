import ReportBoxArea from "./report_box";
import CounselBox from "./counsel_box";

function CardBoxArea(props: any) {
  let myProps = {
    stu_info: {},
    stu_info_arr: [],
    set_stu_info_arr: (inData: any) => { },
    Style: {},
    check_seq_arr: [],
    set_check_seq_arr: (inData: any) => { },
    copy_clipboard_report: (inData: any) => { },
    counsel_todo_arr: [],
    wrtie_counsel_todo_by_ajax: (inData: any) => { },
    open_view_counsel_todo: (inData: any) => { },
    ...props
  };
  let stu_info = myProps.stu_info;
  let Style = myProps.Style;

  return (
    <div className={Style.card_box}>
      <div className={Style.card_box_con}>
        <ReportBoxArea
          stu_info={stu_info}
          stu_info_arr={myProps.stu_info_arr}
          set_stu_info_arr={myProps.set_stu_info_arr}
          Style={Style}
          check_seq_arr={myProps.check_seq_arr}
          set_check_seq_arr={myProps.set_check_seq_arr}
          copy_clipboard_report={myProps.copy_clipboard_report}
          wrtie_counsel_todo_by_ajax={myProps.wrtie_counsel_todo_by_ajax}
          open_view_counsel_todo={myProps.open_view_counsel_todo}
        ></ReportBoxArea>
        {/* <CounselBox
          stu_info={stu_info}
          Style={Style}
        ></CounselBox> */}
      </div>
    </div>
  );
};
export default CardBoxArea;