import AppSoundListArea from "@/pages/mobile/plan/app_sound/common/manage_list";

function WriteSoundPopup(props:any){
   let myProps={
    user_data: {
      "iam_mcomp_seq": "",
      "iam_mcomp_user_seq": "",
      "comp_api_key": "",
    },
    file_upload_data: {
      "a_par_id": "test_app_sound",
      "a_par_seq": "",
      "a_sort1": "audio",
      "a_sort2": "",
      "a_sort3": "",
    },
    callback_upload: (data: any) => { },
    is_upload_at_recored:false,
    closePopup: () => { },
    ...props
  };
  
  return (
  <div>
    <AppSoundListArea
      user_data={myProps.user_data}
      file_upload_data={myProps.file_upload_data}
      callback_upload={myProps.callback_upload}
      is_upload_at_recored={myProps.is_upload_at_recored}
      is_close_btn={true}
      close_func={myProps.closePopup}
    ></AppSoundListArea>
  </div>
  );
};
export default WriteSoundPopup;