import { useRef } from 'react';

function AttendArea(props: any) {
  let myProps = {
    info: {},
    openAttendPopup: (inData: any) => { },
    deleteAttend: (inData: any) => { },
    ...props
  };

  let info = myProps.info;
  const attend_btn_box_ref = useRef<any>(null);

  let attend_in_info:any=info["attend_in_info"];
  let attend_out_info:any=info["attend_out_info"];
  let attend_color:any=info["attend_color"];
  let attend_text_color:any=info["attend_text_color"];
  let attend_state_str:any=info["attend_state_str"];
  let attend_memo:any=info["attend_memo"];
  let attend_time:any=info["attend_time"];
  let attend_out_time:any=info["attend_out_time"];

  const on_attend_click = () => {
    if(attend_in_info!=null){
      if(attend_btn_box_ref.current){
        if(attend_btn_box_ref.current.style.display=="none"){
          attend_btn_box_ref.current.style.display="inline-block";
        }else{
          attend_btn_box_ref.current.style.display="none";
        }
      }
    }else{
      myProps.openAttendPopup([info],"in","attend");
    }
  };

  const go_attend_click = () => {
    if (attend_in_info != null) {
      myProps.openAttendPopup([info], "out", "out");
    } else {
      myProps.openAttendPopup([info], "in", "attend");
    }
  };

  const del_attend_click = () => {
    if (attend_in_info != null) {
      if (attend_out_info != null) {
        myProps.deleteAttend([attend_out_info], "하원 취소 하시겠습니까?");
      } else {
        myProps.deleteAttend([attend_in_info], "등원 취소 하시겠습니까?");
      }
    }
  };

  let attend_btn_box_style: any = {
    marginLeft: "10px",
    display: "none"
  };

  let attend_btn_text = "등원";
  if (attend_in_info != null) {
    attend_btn_text = "하원";
  }

  return (
    <>
      <span style={{ background: attend_color, color: attend_text_color }} >
        <input type="text" value={attend_state_str}
          readOnly
          onClick={on_attend_click}
          style={{ width: "60px", border: 0, textAlign: "center", cursor: "pointer", background: attend_color, color: attend_text_color }} />
      </span>
      <span ref={attend_btn_box_ref} style={attend_btn_box_style}>
        {attend_out_info == null &&
          <button className="btn-s btn-sky" onClick={() => { go_attend_click(); }}>{attend_btn_text}</button>
        }
        {attend_in_info != null &&
          <button className="btn-s btn-red ml-2" onClick={() => { del_attend_click(); }}>취소</button>
        }
      </span>
    </>
  );
};
export default AttendArea;