import strFunc from "@/lib/lyg/string";
import CounselTodoListArea from "@/pages/mobile/manage/counsel_todo/common/list";

function CounselListPopup(props: any) {
  let myProps = {
    select_date: "",
    stu_info_arr: [],
    list_opt: {},
    callback: () => { },
    closePopup: () => { },
    ...props
  };

  let change_list_opt:any=myProps.list_opt;
  if(!strFunc.is_empty(myProps.select_date)){
    change_list_opt={
      "s_start_date": myProps.select_date,
      "s_end_date": myProps.select_date,
    };
  }
  if(!strFunc.is_empty(myProps.stu_info_arr)){
    let stu_seq_arr:any=[];
    for(let i=0;i<myProps.stu_info_arr.length;i++){
      stu_seq_arr.push(myProps.stu_info_arr[i]["a_seq"]);
    }
    change_list_opt={
      ...change_list_opt,
      "s_stu_seq":stu_seq_arr
    };
  }

  return (
    <div>
      <CounselTodoListArea
        listOpt={change_list_opt}
      ></CounselTodoListArea>
    </div>
  );
};
export default CounselListPopup;