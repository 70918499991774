import MobileLayout from "@/pcomponents/mobile/layout/layout";
import HeaderSimple from "@/pcomponents/mobile/layout/header_simple";
import MonthPrescribeCardListArea from "@/pages/mobile/manage/edu/month_prescribe/common/card_list";
import DiscoverMapCardListArea from "@/pages/mobile/manage/edu/discover_map/common/card_list";
import { useState } from 'react';
import TabArea from "./area/tab";
import strFunc from "@/lib/lyg/string";

function MonthPrescribeCardListPage() {
  const [page_tab, set_page_tab] = useState(strFunc.get_storage("month_prescribe_tab", "month_prescribe"));//"month_prescribe", "discover"
  let header_title_str = "학습처방";
  if (page_tab == "discover") {
    header_title_str = "디커버리 맵";
  }
  return (
    <MobileLayout has_header={false}
      container_con_style={{ width: "100%", background: "#F1F4F4" }} >
      <HeaderSimple title={header_title_str} is_show_opt_ico={false}>
        <span style={{ position: "absolute", right: 2, top: 0 }}>
          <TabArea page_tab={page_tab} set_page_tab={set_page_tab}></TabArea>
        </span>
      </HeaderSimple>
      {(page_tab == "month_prescribe") &&
        <div>
          <MonthPrescribeCardListArea></MonthPrescribeCardListArea>
        </div>
      }
      {page_tab == "discover" &&
        <div>
          <DiscoverMapCardListArea></DiscoverMapCardListArea>
        </div>
      }
    </MobileLayout>
  );
};
export default MonthPrescribeCardListPage;