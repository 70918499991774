import CardBox from "./card_box";
import XcolumnFunc from '@/pcomponents/common/xcolumn/xcolumn/xcolumn_func';
import Style from "./css/style.module.css";

function CardListArea(props: any) {
  let myProps = {
    infoArr: [],
    open_discovery_map_write_popup: (inData:any) => { },
    ...props
  };
  let infoArr = myProps.infoArr;
  let stu_grade_option_arr = XcolumnFunc.getSortByTableKey({ 'table': 'user', 'key': 'a_stu_grade' });

  const get_card_box_tags=()=>{
    let card_box_tags:any = [];
    infoArr.forEach((info: any, index: number) => {
      card_box_tags.push(
        <CardBox key={index} info={info} 
          stu_grade_option_arr={stu_grade_option_arr}
          open_discovery_map_write_popup={myProps.open_discovery_map_write_popup}
          Style={Style}></CardBox>
      );
    });
    return card_box_tags;
  };

  return (
    <div>
      {get_card_box_tags()}
    </div>
  );
};
export default CardListArea;