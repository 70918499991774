import red_char from "@/img/mobile/char/red_char.png";
import talk_balloon_r_400 from "@/img/mobile/char/talk_balloon_r_400.png";
import LoadingComponent from "@/pcomponents/common/content/loading";
import { useState, useRef, useEffect } from 'react';

function LoadingArea(props: any) {
  let myProps = {
    ...props
  };

  const countRef = useRef(0);
  const [count, set_count] = useState(0);

  useEffect(() => {
    const count_loop = setInterval(countInterval, 1000);
    return () => {
      clearInterval(count_loop);
    };
  }, []);

  const countInterval = () => {
    countRef.current++;
    set_count(countRef.current);
  };

  let loading_wrap_style:any={
    position: "relative", 
    display: "inline-block", 
    textAlign: "center",
    background:"#ff9",
    padding:"10px 30px",
    borderRadius:"20px",
  };

  return (
    <LoadingComponent
      text={(
        <div style={loading_wrap_style} >
          {count%2==0&&
            <img src={talk_balloon_r_400} style={{position:"absolute",right:0,top:0,width:60}}/>
          }
          <img src={red_char} />
          <p>..</p>
        </div>
      )}
      text_style={{border:"0px"}}
    ></LoadingComponent>
  );
};
export default LoadingArea;