import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import { useState, useRef, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import strFunc from '@/lib/lyg/string';
import DateFunc from '@/lib/lyg/date_func';
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import UserFunc from "@/pcomponents/common/content/user/data/data_func";
import LoadingComponent from "@/pcomponents/common/content/loading";
import TopMoveButton from "@/pcomponents/common/content/list/top_button";
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import DiscoverMapWritePopup from "@/pages/mobile/manage/edu/discover_map/popup/write";
import CardListArea from "./area/card_list";
import SearchArea from "./area/search";

function DiscoverMapCardListArea(props: any) {
  let myProps = {
    ...props
  };
  let user = useSelector((state: any) => state.user);
  let user_select_data = UserFunc.get_iam_mcomp_data({ user: user });
  let iam_mcomp_user_seq = user_select_data.iam_mcomp_user_seq;
  let iam_mcomp_seq = user_select_data.iam_mcomp_seq;
  let comp_api_key = user_select_data.comp_api_key;
  let iam_mcomp_user_grade = user_select_data.iam_mcomp_user_grade;
  let now_date_str = DateFunc.get_date_format(new Date(), "Y-m-d");

  const [listOpt, setListOpt] = useState({
    'now_page': 1,
    'num_per_page': 200,
    'order_id': 'a_date DESC, a_create_date DESC',
    'order_type': '',
    "is_add_idx_info": "1",
    s_date_type: "a_date",
    "s_start_date": DateFunc.get_date_format(new Date(), "Y-m-01"),
    "s_end_date": DateFunc.get_date_format(new Date(), "Y-m-t"),
    s_is_success: "",
  });
  const [infoArr, setInfoArr] = useState([]);
  const [countInfo, setCountInfo] = useState({ "tot": 0 });
  const [check_row_val_arr, set_check_row_val_arr] = useState([]);//[a_ymd,a_seq]
  const [loading_data, set_loading_data] = useState({
    is_display: false,
    text: "저장 되었습니다.",
  });
  const [popupData, setPopupData] = useState<any>({
    isOpen: false,
    sort: "write_discover_map",//write_discover_map
    isUpdate: false,
    row_data: {},
    stu_seq: "",
    is_show_find_stu: false,
    title: "디스커버리맵",
    width: "100%",
    height: "100%",
    y: "0",
    is_border_radius: false,
  });

  useEffect(() => {
    list({});
  }, []);

  const list = (inOptObj: any) => {
    let tmp_listOpt: any = {
      ...listOpt,
      ...inOptObj
    };
    setListOpt(tmp_listOpt);
    let tmp_list_form_json = { ...tmp_listOpt };
    if (strFunc.is_empty(comp_api_key)) {
      return false;
    }
    if (strFunc.is_empty(iam_mcomp_user_seq)) {
      return false;
    }
    tmp_list_form_json["api_key"] = comp_api_key;
    tmp_list_form_json["api_user_seq"] = iam_mcomp_user_seq;

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/discover_map/list', tmp_list_form_json, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setInfoArr(response.data["data"]["info_arr"]);
          setCountInfo(response.data["data"]["count_info"]);
        } else {

        }
      });
  };

  const open_discovery_map_write_popup = (inData:any) => {
    let opt_obj={
      "isUpdate": false,
      "row_data": {},
      "stu_seq": "",
      is_show_find_stu: false,
      ...inData
    };
    setPopupData({
      ...popupData,
      "isOpen": true,
      "sort": "write_discover_map",
      "title": "디스커버리맵",
      "isUpdate": opt_obj.isUpdate,
      "row_data": opt_obj.row_data,
      "stu_seq": opt_obj.stu_seq,
      is_show_find_stu: opt_obj.is_show_find_stu,
    });
  }

  return (
    <div>
      <div>
        <SearchArea
          list_opt={listOpt}
          set_list_opt={setListOpt}
          list={list}
          open_discovery_map_write_popup={open_discovery_map_write_popup}
        ></SearchArea>
      </div>
      <div className="p-2">
        <CardListArea
          infoArr={infoArr}
          open_discovery_map_write_popup={open_discovery_map_write_popup}
        ></CardListArea>
      </div>
      <TopMoveButton></TopMoveButton>
      <Paging now_page={listOpt.now_page}
        num_per_page={listOpt.num_per_page}
        total_rec={countInfo.tot}
        onChangePage={(now_page: number) => { list({ now_page: now_page }); }}
        is_view_num_per_page={true}
        onChangeNumPerPage={(num_per_page: number) => { list({ now_page: 1, num_per_page: num_per_page }); }}></Paging>
      <LoadingComponent is_display={loading_data.is_display} text={loading_data.text}></LoadingComponent>
      {popupData.isOpen &&
        <LayerPopup closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }} title={popupData.title}
          width={popupData.width} height={popupData.height} title_bar_style={{ borderBottom: 0 }}
          content_div_style={{ padding: "10px 0px" }} y={popupData.y} has_header={popupData.has_header}  >
          {popupData.sort === "write_discover_map" &&
            <DiscoverMapWritePopup
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
              row_data={popupData.row_data}
              isUpdate={popupData.isUpdate}
              stu_seq={popupData.stu_seq}
              list={(inOptObj: any) => { list(inOptObj); }}
              select_mcomp_seq={iam_mcomp_seq}
              select_mcomp_api_key={comp_api_key}
              select_mcomp_user_seq={iam_mcomp_user_seq}
              is_show_find_stu={popupData.is_show_find_stu}
            ></DiscoverMapWritePopup>
          }
        </LayerPopup>
      }
    </div>
  );
};
export default DiscoverMapCardListArea;