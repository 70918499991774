import { useState, useRef, useEffect } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import strFunc from '@/lib/lyg/string';
import TitleArea from "./area/title_area";
import CorpInfoArea from "./area/corp_area";

function StuPayInitArea(props: any) {
  let myProps = {
    stu_seq: "",
    user_data: {
      iam_mcomp_seq: "",
      iam_mcomp_user_seq: "",
      comp_api_key: "",
    },
    supply_money: 0,
    supply_seq_arr: [],
    supply_name: "학원비",
    ...props
  };

  let iam_mcomp_seq = myProps.user_data.iam_mcomp_seq;
  let iam_mcomp_user_seq = myProps.user_data.iam_mcomp_user_seq;
  let comp_api_key = myProps.user_data.comp_api_key;

  const [stu_info, set_stu_info] = useState({
    a_seq: "",
    a_user_name: "",
    trade_tot_info: {
      in_debt: 0,
      in_money: 0,
      out_debt: 0,
      out_money: 0,
      remain_money: 0,
    },
  });
  const [corp_info, set_corp_info] = useState({
    a_seq: "",
    a_company_name: "",
    a_represent_name: "",
    a_company_tel: "",
    a_company_busin_num: "",
  });

  useEffect(() => {
    get_stu_info_by_ajax();
    get_corp_info_by_ajax();
  }, []);

  const get_stu_info_by_ajax = () => {
    if (strFunc.is_empty(myProps.stu_seq)) {
      return false;
    }
    if (strFunc.is_empty(comp_api_key)) {
      return false;
    }
    if (strFunc.is_empty(iam_mcomp_user_seq)) {
      return false;
    }
    let form_json_data: any = {
      "s_pri_arr": [myProps.stu_seq],
      s_addon_trade_tot: "1",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
      api_key: comp_api_key,
      api_user_seq: iam_mcomp_user_seq,
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/user/user/list',
      form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          if (response.data["data"]["info_arr"].length > 0) {
            set_stu_info(response.data["data"]["info_arr"][0]);
          }
        } else {

        }
      });
  };

  const get_corp_info_by_ajax = () => {
    if (strFunc.is_empty(comp_api_key)) {
      return false;
    }
    if (strFunc.is_empty(iam_mcomp_user_seq)) {
      return false;
    }
    let form_json_data: any = {
      "s_addon_opt_data": "1",
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
      api_key: comp_api_key,
      api_user_seq: iam_mcomp_user_seq,
    };
    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/corp/corp/list',
      form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          if (response.data["data"]["info_arr"].length > 0) {
            set_corp_info(response.data["data"]["info_arr"][0]);
          }
        } else {

        }
      });
  };

  return (
    <div className="p-2 noto_sans">
      <TitleArea
        supply_name={myProps.supply_name}
        stu_info={stu_info}
        corp_info={corp_info}
        supply_money={myProps.supply_money}
      ></TitleArea>
      <div className="mt-5">
        <CorpInfoArea
          corp_info={corp_info}
        ></CorpInfoArea>
      </div>
    </div>
  );
};
export default StuPayInitArea;