import MobileLayout from "@/pcomponents/mobile/layout/layout";
import HeaderSimple from "@/pcomponents/mobile/layout/header_simple";
import DiscoverMapCardListArea from "@/pages/mobile/manage/edu/discover_map/common/card_list";

function DiscoverMapCardListPage() {
  return (
    <MobileLayout has_header={false}
      container_con_style={{ width: "100%", background: "#F1F4F4" }} >
        <HeaderSimple title={"디스커버리 맵"} is_show_opt_ico={true}></HeaderSimple>
        <DiscoverMapCardListArea></DiscoverMapCardListArea>
    </MobileLayout>
  );
};
export default DiscoverMapCardListPage;