import DisCoverMapWriteArea from "@/pages/mobile/manage/edu/discover_map/common/write";

function DiscoverMapWritePopup(props: any) {
  let myProps = {
    select_mcomp_seq: "",
    select_mcomp_api_key: "",
    select_mcomp_user_seq: "",
    isUpdate: false,
    row_data: {},
    stu_seq: "",
    is_show_find_stu: false,
    list: (inData: any) => { },
    closePopup: () => { },
    ...props
  };

  return (
    <div className="p-2">
      <DisCoverMapWriteArea
        select_mcomp_data={{
          mcomp_seq: myProps.select_mcomp_seq,
          api_key: myProps.select_mcomp_api_key,
          api_user_seq: myProps.select_mcomp_user_seq,
        }}
        stu_seq={myProps.stu_seq}
        rowData={myProps.row_data}
        is_update={myProps.isUpdate}
        is_show_find_stu={myProps.is_show_find_stu}
        list={myProps.list}
        is_close_btn={true}
        close_btn_text={"닫기"}
        close_func={myProps.closePopup}
      ></DisCoverMapWriteArea>
      <div className="mt-1 text-center">
        <button className="btn btn-dark" onClick={myProps.closePopup}>닫기</button>
      </div>
    </div>
  );
};
export default DiscoverMapWritePopup;