import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import { useSelector } from 'react-redux';
import { useState, useRef } from 'react';
import LoadingArea from "./area/loading";
import ChatListArea from "./area/chat_list";

function AIChatPopup(props: any) {
  let myProps = {
    closePopup: () => { },
    ...props
  };
  let user = useSelector((state: any) => state.user);
  let iam_mcomp_user_seq = "";
  let iam_mcomp_seq = "";
  let iam_mcomp_user_grade = "";
  let comp_api_key = "";
  if (user.select_mcomp_link && user.select_mcomp_link["a_mcomp_user_seq"]) {
    iam_mcomp_seq = user.select_mcomp_link["a_mcomp_seq"];
    iam_mcomp_user_seq = user.select_mcomp_link["a_mcomp_user_seq"];
    iam_mcomp_user_grade = user.select_mcomp_link["a_user_grade"];
    comp_api_key = user.select_mcomp_link["comp_api_key"];
  }

  const [chatList, setChatList] = useState<any>([]);//[{role:"",content:""}]//system: 역할을 규정하는 지시문,user:질문자,assistant:답변자
  const [chatInput, setChatInput] = useState('');
  const ChatListAreaRef = useRef<any>();
  const [is_loading, set_is_loading] = useState(false);

  const send_chat_by_ajax = () => {
    if (chatInput == "") {
      alert("내용을 입력해주세요.");
      return false;
    }
    let message = {
      "role": "user",
      "content": chatInput
    };
    let change_messages: any = [...chatList];
    change_messages.push(message);
    setChatList(change_messages);
    setChatInput("");
    set_is_loading(true);

    let json_form_data: any = {
      messages: change_messages,
      request_id: iam_mcomp_seq + "," + iam_mcomp_user_seq,
    };
    json_form_data["api_key"] = comp_api_key;
    json_form_data["api_user_seq"] = iam_mcomp_user_seq;
    my_axios.post(process.env.REACT_APP_API_URL + '/api/main/edu/clova/send_chat_comp',
      json_form_data, get_axios_data())
      .then((response) => {
        set_is_loading(false);
        if (response.data["result"] === "true") {
          if (response.data["data"]["message"]) {
            change_messages.push(response.data["data"]["message"]);
            setChatList([...change_messages]);
          }
          setTimeout(() => {
            ChatListAreaRef.current.setScrollBottom();
          }, 100);
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  return (
    <div style={{ width: "100%", height: "100%", overflow: "hidden", position: "relative" }}>
      <div className="chat_view_wrap">
        <div className="chat_view_header">
          <div className="chat_view_header_pre_box" onClick={() => { myProps.closePopup(); }} >
            <svg xmlns="http://www.w3.org/2000/svg"
              style={{ display: "inline-block" }}
              width="20" height="20" viewBox="0 0 20 20" fill="none">
              <path d="M16.1667 7.54165H4.50374L9.86083 2.18456L8.49999 0.833313L0.833328 8.49998L8.49999 16.1666L9.85124 14.8154L4.50374 9.45831H16.1667V7.54165Z" fill="black" />
            </svg>
          </div>
          <div className="chat_view_header_title">그로봇</div>
          <div className="chat_view_header_mem_box" style={{ background: "#fff" }}  >
            <button className="btn btn-dark" onClick={() => { myProps.closePopup(); }} >X</button>
          </div>
        </div>

        <ChatListArea
          chatList={chatList}
          ref={ChatListAreaRef}
        ></ChatListArea>

        <div className="chat_view_send_wrap" style={{ paddingBottom: 2 }}>
          <div className="chat_view_send_input_box">
            <textarea className="chat_view_send_input"
              placeholder="내용을 입력하세요."
              value={chatInput}
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  //send_chat_by_ajax();
                }
              }}
              onFocus={() => {
                let chat_view_msg_wrap = (document.getElementsByClassName("chat_view_msg_wrap")[0] as HTMLElement);
                chat_view_msg_wrap.scrollTop = chat_view_msg_wrap.scrollHeight;
                setTimeout(() => {
                  chat_view_msg_wrap.scrollTop = chat_view_msg_wrap.scrollHeight;
                }, 200);
              }}
              onChange={(e: any) => {
                setChatInput(e.target.value);
              }}
            ></textarea>
          </div>
          <div className="chat_view_send_btn_box" onClick={() => { send_chat_by_ajax(); }}>
            전송
          </div>
        </div>
      </div>
      {is_loading &&
        <LoadingArea></LoadingArea>
      }
    </div>
  );
};
export default AIChatPopup;