import strFunc from "@/lib/lyg/string";
import MobileScript from "@/lib/mobile/web_script";

function FileListArea(props: any) {
  let myProps = {
    Style:{},
    sound_list: [],
    set_is_play: (inData:boolean) => { },
    select_sound: null,
    default_sound_info: {},
    set_select_sound: (inData:any) => { },
    set_sound_info: (inData:any) => { },
    ...props
  };
  let Style=myProps.Style;
  let sound_list=myProps.sound_list;
  let select_sound=myProps.select_sound;
  let default_sound_info=myProps.default_sound_info;

  const get_byte_str = (byte_num: number) => {
    let byte = parseInt(strFunc.getNumber2(byte_num));
    let byte_str: any = byte;
    if (byte > 1024) {
      byte_str = Math.round(byte / 1024) + "KB";
    }
    if (byte > 1024 * 1024) {
      byte_str = Math.round(byte / (1024 * 1024)) + "MB";
    }
    return byte_str;
  };

  const get_sound_list_tags = () => {
    // return sound_list;
    let sound_list_tags = sound_list.map((item: any, index: number) => {
      let idx_num = index + 1;
      let row_class_name = Style.sound_list_item;
      if (select_sound && select_sound.name === item.name) {
        row_class_name = Style.sound_list_item + " " + Style.active;
      }
      let row_time_json = strFunc.get_time_json_by_msec(item.duration);
      let time_str = strFunc.timeJsonToTimeStr(row_time_json);
      return (
        <div key={index} className={row_class_name} onClick={() => {
          MobileScript.stop_sound();
          MobileScript.set_sound_source(item.absolute_path);
          myProps.set_select_sound(item);
          myProps.set_sound_info({
            ...default_sound_info,
            duration: item.duration,
            current_position: 0,
            percent: 0,
           });
          myProps.set_is_play(false);
        }}>
          <div className={Style.sound_list_item_title}>
            {idx_num}. {item.name} ({get_byte_str(item.length)}) {time_str}
          </div>
          <div className={Style.sound_list_item_manage}>
            <button className="btn btn-red ml-1"
              onClick={() => {
                if (confirm("선택한 파일을 삭제하시겠습니까?") === false) {
                  return;
                }
                MobileScript.remove_sound_file(item.absolute_path);
                setTimeout(() => {
                  MobileScript.get_sound_file_list("callback_sound_list");
                  myProps.set_select_sound(null);
                  myProps.set_sound_info({ ...default_sound_info });
                }, 200);
              }} >X</button>
          </div>
        </div>
      );
    });
    return sound_list_tags;
  };

  return (
    <div className={Style.sound_list_wrap}>
      {get_sound_list_tags()}
    </div>
  );
};
export default FileListArea;