import MobileLayout from "@/pcomponents/mobile/layout/layout";
import AppSoundListArea from "@/pages/mobile/plan/app_sound/common/manage_list";

function AppSoundManagePage(){

  return (
    <MobileLayout container_style={{background:"#fff"}} 
      container_con_style={{width:"100%"}} 
      has_header={true} >
      <div className="mt-2">
        <AppSoundListArea is_upload_btn={false}></AppSoundListArea>
      </div>
    </MobileLayout>
  );
}

export default AppSoundManagePage;