import strFunc from "@/lib/lyg/string";
import MobileScript from "@/lib/mobile/web_script";
import { useState, useRef, useEffect } from 'react';
import Style from "./css/style.module.css";
import { useSelector, useDispatch } from 'react-redux';
import DateFunc from '@/lib/lyg/date_func';
import RecordArea from "./area/record";
import PlayerArea from "./area/player";
import FileListArea from "./area/file_list";

declare global {
  interface Window {
    callback_sound_list(value: string): void;
    callback_sound_play_progress(value: string): void;
    callback_record_state(value: string): void;
    callback_upload_file(value: string): void;
  }
}

function AppSoundListArea(props: any) {
  let myProps = {
    user_data: {
      "iam_mcomp_seq": "",
      "iam_mcomp_user_seq": "",
      "comp_api_key": "",
    },
    file_upload_data: {
      "a_par_id": "test_app_sound",
      "a_par_seq": "",
      "a_sort1": "audio",
      "a_sort2": "",
      "a_sort3": "",
    },
    callback_upload: (data: any) => { },
    is_close_btn: false,
    close_btn_text: "닫기",
    close_func: () => { },
    is_upload_btn: true,
    is_upload_at_recored:false,
    ...props
  };

  let user = useSelector((state: any) => state.user);
  let iam_mcomp_user_seq = "";
  let iam_mcomp_seq = "";
  let comp_api_key = "";
  if (user.select_mcomp_link && user.select_mcomp_link["a_mcomp_user_seq"]) {
    iam_mcomp_seq = user.select_mcomp_link["a_mcomp_seq"];
    iam_mcomp_user_seq = user.select_mcomp_link["a_mcomp_user_seq"];
    comp_api_key = user.select_mcomp_link["comp_api_key"];
  }
  if (!strFunc.is_empty(myProps.user_data.iam_mcomp_user_seq)) {
    iam_mcomp_seq = myProps.user_data.iam_mcomp_seq;
    iam_mcomp_user_seq = myProps.user_data.iam_mcomp_user_seq;
    comp_api_key = myProps.user_data.comp_api_key;
  }

  let default_sound_info = {
    duration: 0,
    current_position: 0,
    percent: 0,
    volume: 1,
  };
  let default_record_time_info = {
    time_num: 0,
  };
  const [sound_list, set_sound_list] = useState<any>([]);
  const [select_sound, set_select_sound] = useState<any>(null);
  const last_sound_info_ref = useRef<any>(null);
  const [sound_info, set_sound_info] = useState<any>({ ...default_sound_info });
  const [is_recording, set_is_recording] = useState<boolean>(false);
  const [is_play, set_is_play] = useState<boolean>(false);
  const [record_time_info, set_record_time_info] = useState<any>({ ...default_record_time_info });
  const loop_interval_time = 300;

  useEffect(() => {
    window.callback_sound_list = (data: any) => {
      if (Array.isArray(data)) {
        data = data.reverse();
      }
      set_sound_list(data);
      if(data){
        last_sound_info_ref.current=data[0];
        set_select_sound(data[0]);
      }
    }
    window.callback_record_state = (data: any) => {
      if (data.state) {
        set_is_recording(true);
      }else{
        set_is_recording(false);
      }
    };
    window.callback_sound_play_progress = (data: any) => {
      if (data["state"] == "end") {
        MobileScript.stop_sound();
        set_sound_info({
          ...sound_info,
          current_position: 0,
          percent: 0,
        });
        set_is_play(false);
      } else if (data["state"] == "info") {
        set_sound_info({
          ...sound_info,
          duration: data["duration"],
          current_position: data["current_position"],
          volume: data["volume"],
        });
      }
    };
    window.callback_upload_file = (data: any) => {
      alert(data.msg);
      myProps.callback_upload(data);
      if(myProps.is_close_btn){
        myProps.close_func();
      }
    };
    MobileScript.get_sound_file_list("callback_sound_list");
    MobileScript.get_record_state("callback_record_state");
  }, []);

  useEffect(() => {
    const play_loop = setInterval(play_loop_func, loop_interval_time);
    return () => {
      clearInterval(play_loop);
    };
  }, [is_play, sound_info, is_recording, record_time_info]);

  const play_loop_func = () => {
    if (is_recording) {
      let tmp_record_time_info = { ...record_time_info };
      tmp_record_time_info.time_num += loop_interval_time;
      set_record_time_info(tmp_record_time_info);
    }

    if (is_play && sound_info.duration != 0) {
      let percent = 0;
      let tmp_sound_info = { ...sound_info };
      tmp_sound_info.current_position += loop_interval_time;
      if (sound_info.duration != 0 && sound_info.current_position != 0) {
        percent = Math.round((tmp_sound_info.current_position / tmp_sound_info.duration) * 100);
        if (percent > 100) {
          percent = 100;
        }
      }
      tmp_sound_info.percent = percent;
      set_sound_info(tmp_sound_info);
      if(percent==100){
        MobileScript.stop_sound();
        set_is_play(false);
      }
    }

  };

  const upload_file_to_app = (inData:any) => {
    let opt_obj={
      file_path:select_sound?select_sound.absolute_path:"",
      ...inData
    };
    if (strFunc.is_empty(opt_obj.file_path)) {
      alert("선택된 파일이 없습니다.");
      return;
    }
    if (confirm("선택한 파일을 업로드 하시겠습니까?") === false) {
      return;
    }
    let file_data_row = {
      "a_ymd": DateFunc.get_date_format(new Date(), "Ymd"),
      "a_seq": "",
      "a_comp_seq": iam_mcomp_seq,
      "a_par_id": "test_app_sound",
      "a_par_seq": "",
      "a_sort1": "audio",
      "a_sort2": "",
      "a_sort3": "",
      "a_writer": user.user_name,
      "a_create_seq": iam_mcomp_user_seq,
      ...myProps.file_upload_data
    };
    file_data_row["a_comp_seq"]=file_data_row["a_comp_seq"]+"";
    let up_file_json_data:any = {
      file_path: opt_obj.file_path,
      send_url: process.env.REACT_APP_FILE_SERVER_URL + "/api/comp/basic/file/file/receive",
      result_func: "callback_upload_file",
      data_arr: [file_data_row],
    };
    let app_sort=MobileScript.checkMobileApp();
    if(app_sort=="ios"){
      up_file_json_data={
        ...up_file_json_data,
        ...file_data_row
      };
    }
    MobileScript.upload_app_file(JSON.stringify(up_file_json_data));
  };

  return (
    <div>
      <RecordArea
        is_recording={is_recording}
        set_is_recording={set_is_recording}
        record_time_info={record_time_info}
        set_record_time_info={set_record_time_info}
        default_record_time_info={default_record_time_info}
        upload_file_to_app={upload_file_to_app}
        last_sound_info_ref={last_sound_info_ref}
      ></RecordArea>
      <PlayerArea
        Style={Style}
        sound_info={sound_info}
        set_sound_info={set_sound_info}
        select_sound={select_sound}
        record_time_info={record_time_info}
        is_play={is_play}
        set_is_play={set_is_play}
        user={user}
        user_data={myProps.user_data}
        file_upload_data={myProps.file_upload_data}
        is_upload_btn={myProps.is_upload_btn}
        upload_file_to_app={upload_file_to_app}
      ></PlayerArea>
      <div className="text-center mt-1">
        리스트
        <button className="btn btn-gray ml-2"
          onClick={() => {
            MobileScript.get_sound_file_list("callback_sound_list");
          }}>검색</button>
      </div>
      <div className="mt-1">
        <FileListArea
          Style={Style}
          sound_list={sound_list}
          set_is_play={set_is_play}
          select_sound={select_sound}
          default_sound_info={default_sound_info}
          set_select_sound={set_select_sound}
          set_sound_info={set_sound_info}
        ></FileListArea>
      </div>
      <div className="text-center mt-2">
        {myProps.is_close_btn &&
          <button className="btn btn-gray" onClick={myProps.close_func}>{myProps.close_btn_text}</button>
        }
      </div>
    </div>
  );
};
export default AppSoundListArea;