import MultyLineTextView from "@/pcomponents/common/crud/view/multy_line_text";
import strFunc from '@/lib/lyg/string';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import { useState, useRef, useEffect } from 'react';
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import WriteSoundPopup from "@/pages/mobile/plan/app_sound/popup/write_sound";
import MapDetailArea from "./map_detail";

function DetailViewArea(props: any) {
  let myProps = {
    user_data: {
      "iam_mcomp_seq": "",
      "iam_mcomp_user_seq": "",
      "comp_api_key": "",
    },
    info: {},
    Style: {},
    select_section_study_name: {},
    set_tab_id: (inData: any) => { },
    get_info_by_ajax: () => { },
    callback_refresh_data: (inData: any) => { },
    is_able_record: false,
    ...props
  };
  let info = myProps.info;
  let Style = myProps.Style;
  let select_section_study_name: any = myProps.select_section_study_name;
  let study_detail_info: any = null;
  if (select_section_study_name["study_detail_info"]) {
    study_detail_info = select_section_study_name["study_detail_info"]
  }

  let iam_mcomp_user_seq = myProps.user_data["iam_mcomp_user_seq"];
  let iam_mcomp_seq = myProps.user_data["iam_mcomp_seq"];
  let comp_api_key = myProps.user_data["comp_api_key"];

  let map_detail_info: any = null;
  if (info.detail_arr && info.detail_arr.length > 0) {
    for (let i = 0; i < info.detail_arr.length; i++) {
      let tmp_detail_info = info.detail_arr[i];
      if (tmp_detail_info["a_section_name"] == select_section_study_name["section_name"] &&
        tmp_detail_info["a_unit_name"] == select_section_study_name["unit_name"]) {
        map_detail_info = tmp_detail_info;
      }
    }
  }

  const [detail_file_arr, set_detail_file_arr] = useState<any>([]);

  const [popupData, setPopupData] = useState<any>({
    isOpen: false,
    sort: "record_sound",//record_sound

    file_row_data: {},

    title: "팝업",
    width: "600px",
    height: "100%",
    y: "0",
    is_border_radius: false,
  });

  useEffect(() => {
    get_detail_file_by_ajax();
  }, [map_detail_info]);

  const get_detail_file_by_ajax = () => {
    let is_able_data = true;
    if (strFunc.is_empty(iam_mcomp_seq)) {
      is_able_data = false;
    }
    if (map_detail_info == null) {
      is_able_data = false;
    } else {
      if (strFunc.is_empty(map_detail_info.a_seq)) {
        is_able_data = false;
      }
    }
    if (strFunc.is_empty(comp_api_key)) {
      is_able_data = false;
    }
    if (strFunc.is_empty(iam_mcomp_user_seq)) {
      is_able_data = false;
    }
    if (is_able_data == false) {
      set_detail_file_arr([]);
      return false;
    }

    let d_pri_val = map_detail_info["a_study_title_seq"] + "," + map_detail_info["a_stu_seq"] + "," + map_detail_info["a_seq"];
    let form_json_data: any = {
      "s_comp_seq": iam_mcomp_seq,
      "s_par_id": "study_map_detail",
      "s_par_seq": d_pri_val,
      "is_need_count": "",
      "is_need_info_arr": "1",
      "is_no_limit": "1",
    };

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/basic/file/file/list', form_json_data, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          set_detail_file_arr(response.data["data"]["info_arr"]);
        } else {
          alert(response.data["msg"]);
        }
      });
  };

  const go_back_page = () => {
    myProps.set_tab_id("section");
  };

  const go_open_record_sound_popup = (inData: any) => {
    let opt_obj = {
      file_row_data: {},
      ...inData
    };
    setPopupData({
      ...popupData,
      "isOpen": true,
      title: "녹음하기",
      sort: "record_sound",
      file_row_data: opt_obj["file_row_data"],
    });
  };

  return (
    <div>
      <div style={{ paddingLeft: 10, color: "gray" }}>
        {select_section_study_name["section_name"]} - {select_section_study_name["unit_name"]}
      </div>
      {study_detail_info &&
        <div style={{ paddingLeft: 10 }}>
          <MultyLineTextView
            text={study_detail_info["a_content"]}
          ></MultyLineTextView>
        </div>
      }
      <div>
        <MapDetailArea
          user_data={myProps.user_data}
          info={info}
          Style={Style}
          select_section_study_name={select_section_study_name}
          map_detail_info={map_detail_info}
          get_info_by_ajax={myProps.get_info_by_ajax}
          callback_refresh_data={myProps.callback_refresh_data}
          go_back_page={go_back_page}
          detail_file_arr={detail_file_arr}
          get_detail_file_by_ajax={get_detail_file_by_ajax}
          is_able_record={myProps.is_able_record}
          go_open_record_sound_popup={go_open_record_sound_popup}
        ></MapDetailArea>
      </div>
      {popupData.isOpen &&
        <LayerPopup closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }} title={popupData.title}
          width={popupData.width} height={popupData.height} y={popupData.y} is_border_radius={popupData.is_border_radius} >
          {popupData.sort === "record_sound" &&
            <WriteSoundPopup
              user_data={myProps.user_data}
              file_upload_data={popupData.file_row_data}
              callback_upload={() => { get_detail_file_by_ajax(); }}
              is_upload_at_recored={true}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
            ></WriteSoundPopup>
          }
        </LayerPopup>
      }
    </div>
  );
};
export default DetailViewArea;