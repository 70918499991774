import TitleBarArea from "@/pcomponents/common/content/list/title_bar";
import SearchArea from "./area/search";
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import strFunc from '@/lib/lyg/string';
import DateFunc from '@/lib/lyg/date_func';
import CardListArea from "./area/card_list";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import TopMoveButton from "@/pcomponents/common/content/list/top_button";
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import WritePopupContent from '@/pages/mobile/manage/edu/month_prescribe/popup/write';
import ViewPopupContent from '@/pages/mobile/manage/edu/month_prescribe/popup/view_content';
import MonthPrescribeListFunc from "@/pcomponents/common/content/edu/month_prescribe/list/list_func";
import { useLocation } from "react-router";

function MonthPrescribeCardListArea() {
  let user = useSelector((state: any) => state.user);
  let iam_mcomp_user_seq = "";
  let iam_mcomp_seq = "";
  let iam_mcomp_user_grade = "";
  let comp_api_key = "";
  if (user.select_mcomp_link && user.select_mcomp_link["a_mcomp_user_seq"]) {
    iam_mcomp_seq = user.select_mcomp_link["a_mcomp_seq"];
    iam_mcomp_user_seq = user.select_mcomp_link["a_mcomp_user_seq"];
    iam_mcomp_user_grade = user.select_mcomp_link["a_user_grade"];
    comp_api_key = user.select_mcomp_link["comp_api_key"];
  }
  const { state } = useLocation();
  let state_obj={
    's_start_date':DateFunc.get_date_format(new Date(),"Y-m-d"),
    's_end_date':DateFunc.get_date_format(new Date(),"Y-m-d"),
    ...state
  };

  const [listOpt, setListOpt] = useState({
    'now_page': 1,
    'num_per_page': 20,
    'order_id': 'a_is_success, a_reserve_date DESC, a_create_date DESC',
    'order_type': '',
    "is_add_idx_info": "1",
    's_date_type': "a_reserve_date",
    's_start_date': DateFunc.get_date_format(new Date(), "Y-m-d"),
    's_end_date': DateFunc.get_date_format(new Date(), "Y-m-d"),
    "s_is_success": "",
    "s_writer_seq": "",
    "s_subject": "",
    "s_stu_name_like":"",
    "s_stu_bookmark": strFunc.get_storage("s_stu_bookmark"),
    "s_addon_user": "1",
    "s_addon_comment":"1",
    "s_addon_counsel": "1",
    "s_addon_file": "1",
    "s_addon_daily_report_last": "1",
    "select_seq": "",
    ...state_obj,
  });
  const [infoArr, setInfoArr] = useState([]);
  const [countInfo, setCountInfo] = useState({ "tot": 0 });

  const [popupData, setPopupData] = useState({
    isOpen: false,
    sort: "write",//view
    isUpdate: false,
    rowData: {},
    stu_seq: "",
    stu_name: "",
    has_header: true,
    title: "팝업",
    width: "800px",
    height: "80%",
  });

  useEffect(() => {
    list({});
  }, []);

  const list = (inData: any) => {
    let changeListOpt = {
      ...listOpt,
      ...inData,
    };
    setListOpt(changeListOpt);

    let tmp_list_opt: any = changeListOpt;

    if (strFunc.is_empty(comp_api_key)) {
      return false;
    }
    if (strFunc.is_empty(iam_mcomp_user_seq)) {
      return false;
    }
    tmp_list_opt["api_key"] = comp_api_key;
    tmp_list_opt["api_user_seq"] = iam_mcomp_user_seq;

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/month_prescribe/list', tmp_list_opt, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          //몇주차 얻기
          response.data["data"]["info_arr"]=MonthPrescribeListFunc.get_list_week_i_by_date({
            info_arr:response.data["data"]["info_arr"],
            select_date: tmp_list_opt.s_start_date,
          });
          response.data["data"]["info_arr"]=MonthPrescribeListFunc.get_list_order_by_comment_arr({
            info_arr:response.data["data"]["info_arr"],
            iam_user_seq:iam_mcomp_user_seq
          });
          setInfoArr(response.data["data"]["info_arr"]);
          setCountInfo(response.data["data"]["count_info"]);
        } else {

        }
      });
  };

  const openOnePopup = (inData: any) => {
    let opt_obj = {
      isUpdate: false,
      sort: "view",
      data_arr: [],
      ...inData
    };
    let isUpdate = opt_obj["isUpdate"];
    let sort = opt_obj["sort"];
    let data_arr = opt_obj["data_arr"];

    let pop_title = "등록";
    let tmp_rowData = {};
    if (isUpdate) {
      pop_title = "수정";
      let selected_row = data_arr;
      if (selected_row.length == 0) {
        alert("선택이 없습니다.");
        return false;
      }
      if (selected_row.length > 0) {
        tmp_rowData = { ...selected_row[0] };
      }
    }
    if (sort == "view") {
      pop_title = "보기";
    }
    setPopupData({
      ...popupData,
      "isOpen": true,
      "isUpdate": isUpdate,
      "rowData": tmp_rowData,
      "title": pop_title,
      "sort": sort,
      width: "800px",
      height: "100%",
    });
  };

  return (
    <div>
      <TopMoveButton></TopMoveButton>
      <SearchArea
        listOpt={listOpt}
        setListOpt={setListOpt}
        list={list}
      ></SearchArea>
      <div className="text-center mt-1 noto_sans">
        <span style={{ color: "blue" }}>{infoArr.length}</span>
        개 검색됨.
        <button className="btn btn-dark ml-1" 
          onClick={()=>{
            openOnePopup({sort:"write"});
          }}>신규등록</button>
      </div>
      <CardListArea
        infoArr={infoArr}
        openOnePopup={openOnePopup}
        list={list}
      ></CardListArea>
      <Paging now_page={listOpt.now_page}
        num_per_page={listOpt.num_per_page}
        total_rec={countInfo.tot}
        onChangePage={(now_page: number) => { list({ now_page: now_page }); }}
        onChangeNumPerPage={(num_per_page: number) => { list({ now_page: 1, num_per_page: num_per_page }); }}></Paging>
      {popupData.isOpen &&
        <LayerPopup closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }} title={popupData.title}
          width={popupData.width} height={popupData.height} has_header={popupData.has_header} y={"0"} is_border_radius={false} >
          {popupData.sort === "write" &&
            <WritePopupContent isUpdate={popupData.isUpdate} rowData={popupData.rowData}
              callback={(inData: any) => { list({ now_page: 1, select_seq: inData.select_seq }); }}
              stu_seq={popupData.stu_seq}
              stu_name={popupData.stu_name}
              list={list}
              is_show_view_popup_btn={false}
              is_show_close_btn={false}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}></WritePopupContent>
          }
          {popupData.sort === "view" &&
            <ViewPopupContent isUpdate={popupData.isUpdate} rowData={popupData.rowData} callback={() => { list({}); }}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}
              openOnePopup={openOnePopup}></ViewPopupContent>
          }
        </LayerPopup>
      }
    </div>
  );
};
export default MonthPrescribeCardListArea;