import { useState, useRef, useEffect } from 'react';
import my_axios, { get_axios_data } from "@/pcomponents/common/axios";
import { useSelector, useDispatch } from 'react-redux';
import strFunc from '@/lib/lyg/string';
import DateFunc from '@/lib/lyg/date_func';
import { useLocation, useNavigate } from "react-router";
import LayerPopup from '@/pcomponents/common/popup/layer/layer_popup';
import CounselViewPopup from "@/pages/mobile/manage/counsel_todo/popup/view";
import TitleBarArea from "./area/title_bar";
import SearchBarArea from "./area/search_bar";
import CardListArea from "./area/card_list";
import Paging from "@/pcomponents/common/crud/list/paging/paging";
import TopMoveButton from "@/pcomponents/common/content/list/top_button";

function CounselTodoListArea(props: any) {
  let myProps = {
    listOpt:{},
    ...props
  };

  const navigate = useNavigate();
  const { state } = useLocation();
  let state_obj = {
    "s_start_date": DateFunc.get_date_format(new Date(), "Y-m-d"),
    "s_end_date": DateFunc.get_date_format(new Date(), "Y-m-d"),
    back_press_url: "/mobile/manage/counsel_todo/list",
    ...state
  };
  let user = useSelector((state: any) => state.user);
  const [listOpt, setListOpt] = useState({
    'now_page': 1,
    'num_per_page': 100,
    'order_id': 'a_reserve_date DESC',
    'order_type': '',
    "is_add_idx_info": "1",
    's_date_type': 'a_reserve_date',
    's_start_date': state_obj.s_start_date,
    's_end_date': state_obj.s_end_date,
    "s_stu_bookmark": strFunc.get_storage("s_stu_bookmark"),
    "s_addon_file": "1",
    "s_addon_user": "1",
    "s_stu_seq": "",
    "s_stu_name_like": "",
    "s_stu_phone_like": "",
    "s_stu_school_name_like": "",
    "s_counseler_like": "",
    "s_title_like": "",
    "s_is_success": "",
    "s_user_grade": "student",//parent
    ...myProps.listOpt
  });

  const [infoArr, setInfoArr] = useState([]); // Set rowData to Array of Objects, one Object per Row
  const [countInfo, setCountInfo] = useState({ "tot": 0 });
  const [is_show_search_bar, set_is_show_search_bar] = useState(false);
  const select_user_grade_arr = [
    { value: "student", text: "학생" },
    { value: "parent", text: "학부모" },
  ];

  const [popupData, setPopupData] = useState<any>({
    isOpen: false,
    select_idx: 0,
    info_arr: [],
    sort: "counsel_view",
    title: "상담보기",
    y: "0",
    width: "500px",
    height: "100%",
    is_border_radius: false,
  });

  let iam_mcomp_user_seq = "";
  let iam_mcomp_seq = "";
  let comp_api_key = "";
  if (user.select_mcomp_link && user.select_mcomp_link["a_mcomp_user_seq"]) {
    iam_mcomp_seq = user.select_mcomp_link["a_mcomp_seq"];
    iam_mcomp_user_seq = user.select_mcomp_link["a_mcomp_user_seq"];
    comp_api_key = user.select_mcomp_link["comp_api_key"];
  }

  useEffect(() => {

    list({});
  }, [user]);

  const list = (inOptObj: any) => {
    let tmp_list_opt2={...listOpt,...inOptObj};
    setListOpt(tmp_list_opt2);

    let tmp_list_opt: any = { ...tmp_list_opt2 };
    if (strFunc.is_empty(comp_api_key)) {
      return false;
    }
    if (strFunc.is_empty(iam_mcomp_user_seq)) {
      return false;
    }
    if (strFunc.is_empty(iam_mcomp_seq)) {
      return false;
    }

    tmp_list_opt["api_key"] = comp_api_key;
    tmp_list_opt["api_user_seq"] = iam_mcomp_user_seq;

    my_axios.post(process.env.REACT_APP_API_URL + '/api/comp/edu/counsel_todo/list', tmp_list_opt, get_axios_data())
      .then((response) => {
        if (response.data["result"] === "true") {
          setInfoArr(response.data["data"]["info_arr"]);
          setCountInfo(response.data["data"]["count_info"]);
        } else {

        }
      });
  };

  const go_write_page = (inData: any) => {
    let opt_obj = {
      "info": {},
      "isUpdate": false,
      ...inData
    };

    let send_data = {
      "row_data": opt_obj.info,
      "info_arr": infoArr,
      "isUpdate": opt_obj.isUpdate,
      "back_press_url": "/mobile/manage/counsel_todo/list",
    };
    let view_url = "/mobile/manage/counsel_todo/write";
    navigate(view_url,
      { state: send_data });
  };

  const open_counsel_view_popup = (inData: any) => {
    let opt_obj = {
      select_idx: 0,
      info_arr: infoArr,
      ...inData,
    };
    setPopupData({
      ...popupData,
      select_idx: opt_obj.select_idx,
      info_arr: opt_obj.info_arr,
      "isOpen": true,
      sort: "counsel_view",
    });
  };

  return (
    <div>
      <div>
        <SearchBarArea
          listOpt={listOpt}
          setListOpt={setListOpt}
          list={list}
          is_show_search_bar={is_show_search_bar}
          set_is_show_search_bar={set_is_show_search_bar}
          go_write_page={go_write_page}
        ></SearchBarArea>
      </div>
      <div className="text-right">
        {infoArr.length} 건 검색됨.
      </div>
      <div className="noto_sans">
        <CardListArea
          info_arr={infoArr}
          open_counsel_view_popup={open_counsel_view_popup}
        ></CardListArea>
        <div style={{ fontFamily: "nanum_slow", marginTop: 5 }}>
          <Paging now_page={listOpt.now_page}
            num_per_page={listOpt.num_per_page}
            total_rec={countInfo.tot}
            onChangePage={(now_page: number) => { list({ now_page: now_page }); }}></Paging>
        </div>
      </div>
      <TopMoveButton></TopMoveButton>
      {popupData.isOpen &&
        <LayerPopup closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }} title={popupData.title}
          width={popupData.width} height={popupData.height} y={popupData.y} is_border_radius={popupData.is_border_radius} >
          {popupData.sort === "counsel_view" &&
            <CounselViewPopup
              select_idx={popupData.select_idx}
              info_arr={popupData.info_arr}
              go_write_page={go_write_page}
              closePopup={() => { setPopupData({ ...popupData, "isOpen": false }); }}></CounselViewPopup>
          }
        </LayerPopup>
      }
    </div>
  );
};
export default CounselTodoListArea;