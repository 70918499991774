import { useNavigate } from 'react-router-dom';
import playground_ico from "@/img/mobile/main/playground_ico.png";
import sotre_ico from "@/img/mobile/main/sotre_ico.png";
import MobileScript from "@/lib/mobile/web_script";
import red_char from "@/img/mobile/char/red_char.png";

function RightButtonArea(props: any) {
  let myProps = {
    open_buy_popup: (inData: any) => { },
    open_ai_chat_popup: (inData: any) => { },
    ...props
  };
  const navigate = useNavigate();

  let base_btn_style = {
    fontWeight: 500,
    fontSize: "18px",
    color: "#fff",
    background: "#b36109",
    lineHeight: "20px",
    padding: 5,
    borderRadius: "10px",
    cursor: "pointer"
  };

  return (
    <div>
      <span style={{ position: "absolute", top: 160, right: 10, zIndex: 99 }}>
        <span style={{ cursor: "pointer" }}
          onClick={() => { navigate("/mobile/playground"); }}>
          <img src={playground_ico} style={{ height: 70 }} />
          <p style={{ textAlign: "center" }}>놀이터</p>
        </span>
        
        <span style={{ cursor: "pointer",display:"inline-block",marginTop:15 }}
          onClick={() => { myProps.open_buy_popup(); }}>
          <img src={sotre_ico} style={{ height: 50 }} />
          <p style={{ textAlign: "center" }}>상점</p>
        </span>
      </span>
      <span style={{ position: "absolute", top: 230, left: 10, zIndex: 99 }}>
        <span style={base_btn_style}
          onClick={()=>{
            navigate("/mobile/plan/study_map/list");
          }} >
          교과맵핑
        </span>
        <div className="mt-4 text-center" >
          <span 
            style={{cursor:"pointer",textAlign:"center",marginLeft:0}}
            onClick={()=>{
              myProps.open_ai_chat_popup();
            }} >
            <img src={red_char} style={{height:40}} />
          </span>
          <p>그로봇</p>
        </div>
      </span>
    </div>
  );
};
export default RightButtonArea;