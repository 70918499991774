//import ArrFunc from '@/lib/lyg/arr_func';
class ArrFunc
{
  //ArrFunc.random(0,1);
  static random(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }
  //let removed_arr=ArrFunc.remove_arr_by_idx(arr,idx);
  static get_remove_arr_by_idx(arr,idx){
    let new_arr=[];
    for(let i=0;i<arr.length;i++){
      if(i!=idx){
        new_arr.push(arr[i]);
      }
    }
    return new_arr;
  }
  //let arr=ArrFunc.get_remove_arr_by_value({arr:[],except_val_arr:[]});
  static get_remove_arr_by_value(inData){
    let opt_obj={
      arr:[],
      except_val_arr:[],
      ...inData
    };
    let arr=opt_obj.arr;
    let except_val_arr=opt_obj.except_val_arr;
    let new_arr=[];
    for(let i=0;i<arr.length;i++){
      let val=arr[i];
      let is_exist_except_val=false;
      for(let j=0;j<except_val_arr.length;j++){
        let except_val=except_val_arr[j];
        if(val==except_val){
          is_exist_except_val=true;
          break;
        }
      }
      if(is_exist_except_val==false){
        new_arr.push(val);
      }
    }
    return new_arr;
  };
  static get_remove_arr_by_value_of_json_arr(inData){
    let opt_obj={
      arr:[],
      except_val_arr:[],
      val_key_arr:[],
      val_split_str:",",
      ...inData
    };
    let arr=opt_obj.arr;
    let except_val_arr=opt_obj.except_val_arr;
    let val_key_arr=opt_obj.val_key_arr;
    let val_split_str=opt_obj.val_split_str;

    let new_arr=[];
    for(let i=0;i<arr.length;i++){
      let row=arr[i];
      let row_pri_val_arr=[];
      for(let j=0;j<val_key_arr.length;j++){
        let val_key=val_key_arr[j];
        row_pri_val_arr.push(row[val_key]);
      }
      let row_pri_val=row_pri_val_arr.join(val_split_str);

      let is_exist_except_val=false;
      for(let j=0;j<except_val_arr.length;j++){
        let except_val=except_val_arr[j];
        if(row_pri_val==except_val){
          is_exist_except_val=true;
          break;
        }
      }
      if(is_exist_except_val==false){
        new_arr.push(row);
      }
    }

    return new_arr;
  }
  static get_random_idx_pick_arr(arr,pick_cnt){
    let new_arr=[];
    for(let i=0;i<pick_cnt;i++){
      if(arr.length==0){
        break;
      }
      let idx=ArrFunc.random(0,arr.length-1);
      new_arr.push(arr[idx]);
      arr=ArrFunc.get_remove_arr_by_idx(arr,idx);
    }
    return new_arr;
  };
  static get_idx_of_plus_minus(inData){
    let opt_obj={
      max_len:0,
      idx:0,
      sort:"",//+,-
      ...inData
    };
    let select_idx=opt_obj.idx;
    let max_len=opt_obj.max_len;
    let sort=opt_obj.sort;
    if(sort=="+"){
      select_idx++;
      if(select_idx>=max_len){
        select_idx=0;
      }
    }else if(sort=="-"){
      select_idx--;
      if(select_idx<0){
        select_idx=max_len-1;
      }
    }
    return select_idx;
  }
  static get_info_of_arr_by_idx(inData){
    let opt_obj={
      info_arr:[],
      idx:0,
      is_select_of_empty:true,
      ...inData
    };
    let info_arr=opt_obj.info_arr;
    let idx=opt_obj.idx;
    let select_info=null;
    for(let i=0;i<info_arr.length;i++){
      let info=info_arr[i];
      if(i==idx){
        select_info=info;
        break;
      }
    }
    if(select_info==null&&info_arr.length.length>0){
      if(opt_obj.is_select_of_empty){
        select_info=info_arr[0];
      }
    }
    return select_info;
  }
}
export default ArrFunc;