import strFunc from "@/lib/lyg/string";
import MobileScript from "@/lib/mobile/web_script";

function RecordArea(props: any) {
  let myProps = {
    is_recording: false,
    set_is_recording: (inData: any) => { },
    record_time_info: {},
    set_record_time_info: (inData: any) => { },
    default_record_time_info: {},
    upload_file_to_app: (inData: any) => { },
    last_sound_info_ref:null,
    ...props
  };
  let is_recording = myProps.is_recording;
  let record_time_info=myProps.record_time_info;
  let default_record_time_info=myProps.default_record_time_info;
  let record_time_json = strFunc.get_time_json_by_msec(record_time_info.time_num);

  return (
    <div className="text-center">
      <div>
        {is_recording ? <span style={{ color: "blue", marginLeft: 10 }}>녹음중 </span> : <span></span>}
        <span className="ml-2" style={{ fontSize: "20px" }}>
          {strFunc.timeJsonToTimeStr(record_time_json)}
        </span>
      </div>
      {is_recording == false &&
        <button className="btn-l btn-blue ml-1"
          onClick={() => {
            MobileScript.start_recording();
            myProps.set_is_recording(true);
          }}>녹음시작</button>
      }
      {is_recording &&
        <button className="btn-l btn-round-orange ml-1"
          onClick={() => {
            MobileScript.stop_recording();
            myProps.set_is_recording(false);
            setTimeout(() => {
              MobileScript.get_sound_file_list("callback_sound_list");
              myProps.set_record_time_info({ ...default_record_time_info });
            }, 200);
            setTimeout(()=>{
              let last_sound_path="";
              if(myProps.last_sound_info_ref.current){
                last_sound_path=myProps.last_sound_info_ref.current.absolute_path;
                myProps.upload_file_to_app({
                  file_path:last_sound_path
                });
              }
            },1000);
          }}>녹음완료</button>
      }
    </div>
  );
};
export default RecordArea;