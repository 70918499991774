import strFunc from "@/lib/lyg/string";
import MobileScript from "@/lib/mobile/web_script";

function PlayerArea(props: any) {
  let myProps = {
    Style: {},
    sound_info: {},
    set_sound_info: (inData: any) => { },
    select_sound: {},
    record_time_info: {},
    is_play: false,
    set_is_play: (inData: boolean) => { },
    user: {},
    user_data: {
      "iam_mcomp_seq": "",
      "iam_mcomp_user_seq": "",
      "comp_api_key": "",
    },
    file_upload_data: {},
    is_upload_btn: true,
    upload_file_to_app: (inData:any) => { },
    ...props
  };

  let user = myProps.user;
  let iam_mcomp_user_seq = "";
  let iam_mcomp_seq = "";
  let comp_api_key = "";
  if (user.select_mcomp_link && user.select_mcomp_link["a_mcomp_user_seq"]) {
    iam_mcomp_seq = user.select_mcomp_link["a_mcomp_seq"];
    iam_mcomp_user_seq = user.select_mcomp_link["a_mcomp_user_seq"];
    comp_api_key = user.select_mcomp_link["comp_api_key"];
  }
  if (!strFunc.is_empty(myProps.user_data.iam_mcomp_user_seq)) {
    iam_mcomp_seq = myProps.user_data.iam_mcomp_seq;
    iam_mcomp_user_seq = myProps.user_data.iam_mcomp_user_seq;
    comp_api_key = myProps.user_data.comp_api_key;
  }

  let Style = myProps.Style;
  let sound_info = myProps.sound_info;
  let select_sound = myProps.select_sound;
  let record_time_info = myProps.record_time_info;
  let is_play = myProps.is_play;

  let record_time_json = strFunc.get_time_json_by_msec(record_time_info.time_num);
  let total_time_json = strFunc.get_time_json_by_msec(sound_info.duration);
  let play_time_json = strFunc.get_time_json_by_msec(sound_info.current_position);

  let volumn_val = parseFloat(sound_info.volume + "") * 100;
  return (
    <div className={Style.select_sound_wrap}>
      <div>
        <input type="range" min={0} max={sound_info.duration} value={sound_info.current_position}
          style={{ width: "80%" }}
          onChange={(e: any) => {
            let seek_position = parseInt(strFunc.getNumber2(e.target.value));
            myProps.set_sound_info({
              ...sound_info,
              current_position: seek_position,
              percent: Math.round((seek_position / sound_info.duration) * 100),
            });
            myProps.set_is_play(false);
            MobileScript.stop_sound();
            MobileScript.seek_to_position(seek_position);
          }} />
      </div>
      <div>
        소리
        <input type="range" min={0} max={100} value={volumn_val}
          style={{ width: "100px" }}
          onChange={(e: any) => {
            let change_volume: any = parseInt(strFunc.getNumber2(e.target.value));
            if (change_volume > 0) {
              change_volume = change_volume / 100;
            }

            myProps.set_sound_info({
              ...sound_info,
              volume: change_volume,
            });
            change_volume=change_volume+"";
            MobileScript.set_volume(change_volume);
          }} />
      </div>
      <div>
        {strFunc.timeJsonToTimeStr(play_time_json) +
          "/" + strFunc.timeJsonToTimeStr(total_time_json)} ({sound_info.percent}%)
      </div>
      <div>
        {select_sound == null ? "선택없음." : select_sound.name}
      </div>
      <div>
        {is_play == false &&
          <button className="btn-l btn-round-green2" style={{ width: "40%" }}
            onClick={() => {
              if (select_sound == null) {
                alert("선택된 파일이 없습니다.");
                return;
              }
              MobileScript.play_sound(select_sound.absolute_path, "callback_sound_play_progress");
              myProps.set_is_play(true);
            }} >▶</button>
        }
        {is_play &&
          <button className="btn-l btn-round-green2" style={{ width: "40%" }}
            onClick={() => {
              MobileScript.stop_sound();
              myProps.set_is_play(false);
            }} >■</button>
        }

        {(select_sound != null&&myProps.is_upload_btn) &&
          <button className="btn-l btn-blue ml-2" style={{ width: "40%" }}
            onClick={() => {
              myProps.upload_file_to_app({});
            }} >업로드</button>
        }
      </div>
    </div>
  );
};
export default PlayerArea;