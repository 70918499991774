function CardBox(props: any) {
  let myProps = {
    info: null,
    stu_grade_option_arr: [],
    open_discovery_map_write_popup: (inData: any) => { },
    Style: {},
    ...props
  };
  let info = myProps.info;
  let Style = myProps.Style;

  let is_req_prescribe = false;
  let is_success = false;
  if (info["a_is_req_prescribe"] == "1") {
    is_req_prescribe = true;
  }
  if (info["a_is_success"] == "1") {
    is_success = true;
  }

  let card_box_class_name = Style.card_box;
  if (is_success) {
    card_box_class_name += " " + Style.is_success_box;
  }

  return (
    <div className={card_box_class_name}>
      <table style={{ width: "100%" }}>
        <colgroup>
          <col style={{ width: "60%" }} />
          <col style={{ width: "40%" }} />
        </colgroup>
        <tbody>
          <tr>
            <td className="text-left">
              <span className="bold">{info["a_stu_name"]}</span>
              <span className="ml-1" style={{ color: "green" }}>{info["a_stu_total_code"]}</span>
              <span className="ml-1" style={{ color: "gray" }}>{info["a_stu_think_type"]}</span>
              <span className="ml-1" style={{ color: "gray" }}>{info["a_stu_grade"]}</span>
            </td>
            <td className="text-right" rowSpan={2}>
              <span >{info["a_date"]}</span>
              <button className="btn btn-dark ml-2"
                onClick={() => {
                  myProps.open_discovery_map_write_popup({
                    "isUpdate": true,
                    "row_data": info,
                    "stu_seq": info["a_stu_seq"]
                  });
                }} >보기</button>
            </td>
          </tr>
          <tr>
            <td colSpan={2} className="text-left">
              <span style={{ color: "gray", fontSize: "16px" }}>작성: {info["a_writer"]}</span>
              {(is_req_prescribe && is_success == false) &&
                <span className="ml-2" style={{ color: "gray", fontSize: "16px" }}>처방요청</span>
              }
              {is_success &&
                <span className="ml-2" style={{ color: "green", fontSize: "16px" }}>처방완료</span>
              }
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
export default CardBox;