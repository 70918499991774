import Style from "./css/style.module.css";
import left_arrow_ico from "@/img/ico/arrow/left_arrow_black.png";
import { useNavigate } from 'react-router-dom';
import option_ico_green_100 from "@/img/mobile/home/option_ico_green_100.png";

function HeaderSimple(props: any) {
  let myProps = {
    title: "",
    back_url: "/mobile",
    is_show_opt_ico: false,
    ...props
  };
  
  const navigate = useNavigate();
  let option_src = option_ico_green_100;
  return (
    <div className={Style.header + " noto_sans"} >
      <div className={Style.header_con}>
        <span className={Style.header_left}
          onClick={() => { navigate(myProps.back_url); }} >
          <img src={left_arrow_ico} style={{ height: 17 }} />
        </span>
        {myProps.title}
        <span className={Style.header_right}>
          {myProps.is_show_opt_ico &&
            <a onClick={() => { navigate("/mobile/setting") }} >
              <img src={option_src} className="ml-2" style={{ height: 27 }} />
            </a>
          }
        </span>
        {myProps.children}
      </div>
    </div>
  );
};
export default HeaderSimple;