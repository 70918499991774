import { forwardRef,useImperativeHandle,useRef } from 'react';
import ChatRowTag from "./row";

const ChatListArea = forwardRef((props:any, ref) => {
  let myProps = {
    chatList:[],
    ...props
  };
  let chatList = myProps.chatList;

  const chat_view_msg_wrap_ref=useRef<any>();
  
    useImperativeHandle(ref, () => ({
      // 부모 컴포넌트에서 사용할 함수를 선언
      setScrollBottom
    }));
    
    const setScrollBottom=()=>{
      if(chat_view_msg_wrap_ref.current){
        (chat_view_msg_wrap_ref.current as HTMLElement).scrollTop=(chat_view_msg_wrap_ref.current as HTMLElement).scrollHeight;
      }
    };

  const show_chat_tags = () => {
    let chat_tags = chatList.map((chat: any, idx: number) => {
      let user_name_style:any={color:"blue",fontWeight:"bold"};
      let role_str = "그로봇";
      if (chat.role == "user") {
        role_str = "사용자";
        user_name_style={color:"green"};
      }
      return (
        <ChatRowTag
          key={idx}
          chat_row={chat}
        ></ChatRowTag>
      );
    });
    return chat_tags;
  };

  return (
    <div className="chat_view_msg_wrap" ref={chat_view_msg_wrap_ref} >
      {show_chat_tags()}
    </div>
  );
});
export default ChatListArea;