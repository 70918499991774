import strFunc from "@/lib/lyg/string";

function CorpInfoArea(props: any) {
  let myProps = {
    corp_info: {},
    ...props
  };
  let corp_info = myProps.corp_info;
  let account_info: any = {
    a_comp_account_bank: "",
    a_comp_account_num: "",
    a_comp_account_owner: "",
  };
  if (corp_info.opt_data_info) {
    account_info = {
      ...account_info,
      ...corp_info.opt_data_info
    };
  }

  const show_account_tr = () => {
    if (strFunc.is_empty(account_info["a_comp_account_bank"])
      || strFunc.is_empty(account_info["a_comp_account_num"])
      || strFunc.is_empty(account_info["a_comp_account_owner"])) {
      return "";
    }

    return (
      <>
        <tr>
          <th>은행,예금주</th>
          <td>
            {account_info["a_comp_account_bank"]} {account_info["a_comp_account_owner"]}
          </td>
        </tr>
        <tr>
          <th>계좌</th>
          <td>
            {account_info["a_comp_account_num"]}
            <button className="btn btn-dark ml-1"
              onClick={() => {
                navigator.clipboard.writeText(account_info["a_comp_account_bank"]+" "+account_info["a_comp_account_owner"]+" "+account_info["a_comp_account_num"]);
                alert("복사됨.");
              }}>복사</button>
          </td>
        </tr>
      </>
    );
  };

  return (
    <div>
      <div className="write-table-div">
        <table>
          <colgroup>
            <col width={"25%"}></col>
            <col width={"75%"}></col>
          </colgroup>
          <tbody>
            <tr>
              <th>연락처</th>
              <td>{corp_info.a_company_tel}</td>
            </tr>
            {show_account_tr()}
          </tbody>
        </table>
      </div>
    </div>
  );
};
export default CorpInfoArea;