import ManageTStuTodoListPage from "@/pages/mobile/manage/tea/tstu_todo/list";
import CounselDailyListPage from "@/pages/mobile/manage/counsel_todo/counsel_daily";
import DiscoverMapCardListPage from "@/pages/mobile/manage/edu/discover_map/card_list";

const manageRouterArr:object[] = [
  {
    path: "/mobile/manage/tea/tstu_todo/list",
    element: <ManageTStuTodoListPage></ManageTStuTodoListPage>,
  },
  {
    path: "/mobile/manage/counsel_todo/counsel_daily",
    element: <CounselDailyListPage></CounselDailyListPage>,
  },
  {
    path: "/mobile/manage/discover_map/card_list",
    element: <DiscoverMapCardListPage></DiscoverMapCardListPage>,
  },
];

export default manageRouterArr;