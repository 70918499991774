import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";
import DateFunc from '@/lib/lyg/date_func';
import { customHeaderFunc } from '@/pcomponents/common/date_picker/custom_header';
import { CustomInputSearch } from '@/pcomponents/common/date_picker/custom_input';
import strFunc from "@/lib/lyg/string";

function SearchArea(props: any) {
  let myProps = {
    list_opt: {},
    set_list_opt: (inData: any) => { },
    list: (inData: any) => { },
    open_discovery_map_write_popup: (inData: any) => { },
    ...props
  };
  let list_opt = myProps.list_opt;

  const onChangeDatePicker = (key: string, date: Date) => {
    let date_str = DateFunc.get_date_format(date, "Y-m-d");
    let change_list_opt = {
      now_page: 1,
      [key]: date_str
    };
    myProps.set_list_opt(change_list_opt);
    myProps.list(change_list_opt);
  };

  const go_change_date_by_sort = (inData: any) => {
    let opt_obj = {
      sort: "empty",
      ...inData
    };
    let sort = opt_obj.sort;
    let change_list_opt: any = {
      ...list_opt,
      now_page: 1,
      s_start_date: "",
      s_end_date: "",
    };
    if (sort == "today") {
      let today_date = DateFunc.get_date_format(new Date(), "Y-m-d");
      change_list_opt.s_start_date = today_date;
      change_list_opt.s_end_date = today_date;
    } else if (sort == "month") {
      change_list_opt.s_start_date = DateFunc.get_date_format(new Date(), "Y-m-01");
      change_list_opt.s_end_date = DateFunc.get_date_format(new Date(), "Y-m-t");
    } else if (sort == "pre_month") {
      change_list_opt.s_start_date = DateFunc.get_date_format(DateFunc.get_change_date(new Date(), 'month', -1), "Y-m-01");
      change_list_opt.s_end_date = DateFunc.get_date_format(DateFunc.get_change_date(new Date(), 'month', -1), "Y-m-t");
    }
    myProps.set_list_opt(change_list_opt);
    myProps.list(change_list_opt);
  };
  const go_change_date_by_pre_after = (inData: any) => {
    let opt_obj = {
      "pre_after": "",//pre,after
      "sort": "month",//day,month
      ...inData
    };
    let select_date_str = list_opt.s_start_date;
    if (strFunc.is_empty(select_date_str)) {
      select_date_str = DateFunc.get_date_format(new Date(), "Y-m-d");
    }
    if (opt_obj.sort != "day") {
      select_date_str = DateFunc.get_date_format(new Date(select_date_str), "Y-m-01");
    }

    let plus_minus_num = 1;
    if (opt_obj["pre_after"] == "pre") {
      plus_minus_num = -1;
    }
    let start_date_str = "";
    let end_date_str = "";
    if (opt_obj.sort == "day") {
      start_date_str = DateFunc.get_date_format(DateFunc.get_change_date(new Date(select_date_str), opt_obj["sort"], plus_minus_num), "Y-m-d");
      end_date_str = start_date_str;
    } else {
      start_date_str = DateFunc.get_date_format(DateFunc.get_change_date(new Date(select_date_str), opt_obj["sort"], plus_minus_num), "Y-m-01");
      end_date_str = DateFunc.get_date_format(DateFunc.get_change_date(new Date(select_date_str), opt_obj["sort"], plus_minus_num), "Y-m-t");
    }

    let change_list_opt: any = {
      ...list_opt,
      now_page: 1,
      s_start_date: start_date_str,
      s_end_date: end_date_str,
    };
    myProps.set_list_opt(change_list_opt);
    myProps.list(change_list_opt);
  };

  const handleInputChange = (event: any) => {
    const target = event.target;
    let value = target.value;
    const name = target.name;
    let change_list_opt = { ...list_opt };

    change_list_opt[name] = value;
    if (target.type === 'checkbox' || target.type === 'radio') {
      value = "";
      if (target.checked) {
        value = target.value;
      }
    }
    if (name == "s_search_text") {
      return false;
    }
    myProps.set_list_opt(change_list_opt);
    myProps.list({ ...change_list_opt, now_page: 1 });
  };

  return (
    <div>
      <div className="text-center">
        <div style={{ display: "inline-block", width: 90 }}>
          <DatePicker
            wrapperClassName="pl-1"
            selected={list_opt.s_start_date != "" ? new Date(list_opt.s_start_date) : null}
            onChange={(date: Date) => {
              onChangeDatePicker("s_start_date", date);
            }}
            locale={ko}
            dateFormat="yyyy-MM-dd"
            customInput={<CustomInputSearch />}
            renderCustomHeader={customHeaderFunc}
          />
        </div>
        ~
        <div style={{ display: "inline-block", width: 90 }}>
          <DatePicker
            wrapperClassName="pl-1"
            selected={list_opt.s_end_date != "" ? new Date(list_opt.s_end_date) : null}
            onChange={(date: Date) => {
              onChangeDatePicker("s_end_date", date);
            }}
            locale={ko}
            dateFormat="yyyy-MM-dd"
            customInput={<CustomInputSearch />}
            renderCustomHeader={customHeaderFunc}
          />
        </div>
        <button className="btn btn-dark ml-1" onClick={() => { go_change_date_by_sort({ sort: "today" }); }}>오늘</button>
        <button className="btn btn-dark ml-1" onClick={() => { go_change_date_by_sort({ sort: "month" }); }}>이번달</button>
        <button className="btn btn-dark ml-1" onClick={() => { go_change_date_by_sort({ sort: "pre_month" }); }}>지난달</button>
        {/* <button className="btn btn-gray ml-1" onClick={() => { go_change_date_by_sort({}); }}>전체</button> */}
      </div>
      <div className="text-center mt-1">
        <button className="btn btn-gray ml-1" onClick={() => { go_change_date_by_pre_after({ pre_after: "pre", sort: "month" }); }}>◀</button>
        <button className="btn btn-gray ml-1" onClick={() => { go_change_date_by_pre_after({ pre_after: "pre", sort: "day" }); }}>◁</button>
        <button className="btn btn-gray ml-1" onClick={() => { go_change_date_by_pre_after({ pre_after: "after", sort: "day" }); }}>▷</button>
        <button className="btn btn-gray ml-1" onClick={() => { go_change_date_by_pre_after({ pre_after: "after", sort: "month" }); }}>▶</button>
        <select className="border ml-1" name="s_is_success" value={list_opt.s_is_success} onChange={handleInputChange}>
          <option value="">전체</option>
          <option value="1">처방완료</option>
          <option value="empty">미처방</option>
        </select>
        <button className="btn btn-blue ml-1" 
          onClick={()=>{
            myProps.open_discovery_map_write_popup({
              "isUpdate": false,
              "row_data": {},
              "stu_seq": "",
              is_show_find_stu: true,
            });
          }} >신규작성</button>
      </div>
    </div>
  );
};
export default SearchArea;